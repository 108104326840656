import PropTypes from "prop-types";
import React from "react";

import * as classes from "./menu_button_icon.module.scss";

function MenuButtonIcon({ isActive, color, onClick }) {
  const classNames = [classes.icon, classes[`color_${color}`]];

  if (isActive) {
    classNames.push(classes.isActive);
  }
  return (
    <button className={classes.button} type="button" onClick={onClick}>
      <div className={classNames.join(" ")}>
        <span className={classes.shape} />
        <span className={classes.shape} />
        <span className={classes.shape} />
        <span className={classes.shape} />
      </div>
    </button>
  );
}

MenuButtonIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

MenuButtonIcon.defaultProps = {
  onClick: null,
};
export default MenuButtonIcon;
