import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { ensureTrailingSlash } from "~utils/url_utils/ensureTrailingSlash/ensureTrailingSlash";
import { removeDefaultLanguagePrefix } from "~utils/url_utils/remove_default_language_prefix/remove_default_language_prefix";

export default function getEnglishVersionSlug({
  origin,
  alternates,
  language,
  fullSlug,
}) {
  if (alternates && alternates.length > 0) {
    // The english alternate is not returned in the alternates response
    // We need this for a self-referring alternate link
    if (language === "en-gb") {
      const englishVersionSlug = createUrlPathFromArray([origin, fullSlug]);
      let sanitisedEnglishVersionSlug =
        removeDefaultLanguagePrefix(englishVersionSlug);
      sanitisedEnglishVersionSlug = ensureTrailingSlash(
        sanitisedEnglishVersionSlug
      );
      return sanitisedEnglishVersionSlug;
    }

    const [englishAlternate] = alternates.filter((item) => {
      return item.full_slug.startsWith("en-gb/");
    });

    if (englishAlternate) {
      const { full_slug: englishAlternateSlug } = englishAlternate || {};

      const englishVersionSlug = createUrlPathFromArray([
        origin,
        englishAlternateSlug,
      ]);

      let sanitisedEnglishSlug =
        removeDefaultLanguagePrefix(englishVersionSlug);
      sanitisedEnglishSlug = ensureTrailingSlash(sanitisedEnglishSlug);
      return sanitisedEnglishSlug;
    }
    return null;
  }
  return null;
}
