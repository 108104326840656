import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Interactive } from "react-interactive";
import SbEditable from "storyblok-react";
import Contain from "~components/atoms/contain/contain";
import Button from "~components/molecules/button/button/button";
import Banner from "~components/organisms/header/header_components/banner/banner";
import DesktopNav from "~components/organisms/header/header_components/desktop_nav/desktop_nav";
import HideOnScrollWrapper from "~components/organisms/header/header_components/hide_on_scroll_wrapper/hide_on_scroll_wrapper";
import LanguageSelect from "~components/organisms/header/header_components/language_select/language_select";
import MobileNav from "~components/organisms/header/header_components/mobile_nav/mobile_nav";
import { BLOK_ARRAY, LINK, MEDIA } from "../../../prop_types";
// import Search from "../search/global_search/global_search";
import * as classes from "./header.module.scss";
import NavHomeButton from "./header_components/home_button/home_button";
import useInteractiveStateChangeWithTimeout from "./hooks/use_interactive_state_change_with_timeout/use_interactive_state_change_with_timeout";

/* ——————————————————————————————————————————————————————————————————————————————
//      NOTE                                                                    
// —————————————————————————————————————————————————————————————————————————————— */
//  At some point in the past the `color` prop got confused,
// so it can be set as either `white` or `blue`, which style text,
// or `whiteWithShadow` which styles background
// ToDo: Decouple header color prop from both text color and background color
export default function Header({ content, color }) {
  const [banner] = content?.banner || [null];

  const [isHeaderHovered, setIsHeaderHovered] = useState(false);
  const [isHeaderScrolled, setIsHeaderScrolled] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDesktopNavOpen, setIsDesktopNavOpen] = useState(false);
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] = useState(false);

  const shouldUseSolidBackground =
    color === "whiteWithShadow" ||
    isLanguageSelectOpen ||
    isHeaderScrolled ||
    isMobileMenuOpen ||
    isHeaderHovered;

  const shouldHaveShadow =
    color === "whiteWithShadow" ||
    (color !== "blue" && isHeaderHovered) ||
    isHeaderScrolled;

  const shouldUseCloudBurstText = color === "blue" || shouldUseSolidBackground;
  const shouldUseWhiteText = color === "white" && !shouldUseSolidBackground;

  const headerProps = {
    textColor: shouldUseSolidBackground ? "blue" : color,
  };

  const headerClassNames = classNames(classes.headerWrapperStyle, {
    [classes.whiteTextStyle]: shouldUseWhiteText,
    [classes.blueTextStyle]: shouldUseCloudBurstText,
    [classes.whiteBackgroundStyle]: shouldUseSolidBackground,
    [classes.shadowStyle]: shouldHaveShadow,
  });

  const buttonProps = {
    text: content.buttonText,
    color: shouldUseWhiteText ? "whiteTransparent" : "yellow",
    link: content.buttonLink,
    chevronType: "chevronRight",
  };

  const handleInteractiveStateChange = useInteractiveStateChangeWithTimeout({
    callbackToSetState: setIsHeaderHovered,
    enterTimeout: 250,
    exitTimeout: 750,
  });

  if (!content || !content.items) {
    return <div>Missing nav menu component</div>;
  }

  return (
    <SbEditable content={content}>
      {banner && <Banner {...banner} />}
      <HideOnScrollWrapper callbackWhenScrolled={setIsHeaderScrolled}>
        <Interactive
          as="header"
          onStateChange={handleInteractiveStateChange}
          className={headerClassNames}
        >
          <Contain>
            <div className={classes.headerInner}>
              <div className={classes.headerLeftStyle}>
                {/* ToDo: (AMG) Clean up field structure for header component */}
                <NavHomeButton
                  logoWhite={content.logoWhite}
                  logoCloudBurst={content.logoCloudBurst}
                  logomarkWhite={content.logomarkWhite}
                  logomarkSun={content.logomarkSun}
                  logoLink={content.logoLink}
                  shouldUseWhiteLogoText={shouldUseWhiteText}
                />
                <DesktopNav
                  items={content.items}
                  isHeaderScrolled={isHeaderScrolled}
                  isMobileMenuOpen={isMobileMenuOpen}
                  callbackWhenSubMenuOpen={setIsDesktopNavOpen}
                />
              </div>
              <div className={classes.headerRightStyle}>
                <div className={classes.languageSelectWrapperStyle}>
                  <LanguageSelect
                    languages={content.languages}
                    isHeaderScrolled={isHeaderScrolled}
                    callbackOnToggle={setIsLanguageSelectOpen}
                    isOtherSubMenuOpen={isMobileMenuOpen || isDesktopNavOpen}
                  />
                </div>
                <Button {...buttonProps} hasChevron>
                  {buttonProps.text}
                </Button>
                {/* <Search /> */}
                <MobileNav
                  color={headerProps.textColor}
                  items={content.items}
                  callbackOnMobileMenuOpen={setMobileMenuOpen}
                  isLanguageSelectOpen={isLanguageSelectOpen}
                />
              </div>
            </div>
          </Contain>
        </Interactive>
      </HideOnScrollWrapper>
    </SbEditable>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["white", "blue"]),
  content: PropTypes.shape({
    banner: BLOK_ARRAY,
    items: BLOK_ARRAY,
    languages: BLOK_ARRAY,
    logoCloudBurst: MEDIA,
    logoWhite: MEDIA,
    logomarkSun: MEDIA,
    logomarkWhite: MEDIA,
    logoLink: LINK,
    buttonLink: LINK,
    buttonText: PropTypes.string,
  }),
};

Header.defaultProps = {
  color: "blue",
  content: null,
};
