import React from "react";
import PropTypes from "prop-types";
import { Interactive } from "react-interactive";
import SbEditable from "storyblok-react";
import ChevronSmall from "~components/atoms/chevron_small/chevron_small";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import SVGIcon from "~components/utils/svg_icon/svg_icon";
import useHandleInteractiveStateChange from "~hooks/use_handle_interactive_state_change/use_handle_interactive_state_change";
import { BLOK_ARRAY, LINK } from "../../../prop_types";
import Tag from "../tags/tag";
import {
  iconStyle,
  linkWrapperStyle,
  navLinkStyle,
  subtitleStyle,
  titleStyle,
  titleWrapperStyle,
} from "./nav_link.module.scss";

export default function NavLink({
  hasChevron,
  icon: iconArray,
  link,
  onClick,
  statusLabel,
  subtitle,
  title,
  ...rest
}) {
  const [icon] = iconArray || [];

  const [isActive, handleInteractiveStateChange] =
    useHandleInteractiveStateChange();

  if (title && (link || onClick)) {
    return (
      <SbEditable content={rest}>
        <Interactive
          as={LinkWrapper}
          link={link}
          onClick={onClick}
          onStateChange={handleInteractiveStateChange}
          className={linkWrapperStyle}
        >
          <div className={navLinkStyle} data-testid="nav-link">
            {icon && (
              <div className={iconStyle} role="img">
                <SVGIcon width={32} height={32} {...icon} />
              </div>
            )}
            <div className={titleWrapperStyle}>
              <div className={titleStyle}>
                {title}
                {statusLabel && (
                  <Tag size="xs" color="azure" title={statusLabel} />
                )}
                {hasChevron && (
                  <ChevronSmall
                    chevronType="chevronRightHollow"
                    isActive={isActive}
                  />
                )}
              </div>

              {subtitle && <div className={subtitleStyle}>{subtitle}</div>}
            </div>
          </div>
        </Interactive>
      </SbEditable>
    );
  }
  return null;
}

NavLink.propTypes = {
  hasChevron: PropTypes.bool,
  icon: BLOK_ARRAY,
  link: LINK,
  onClick: PropTypes.func,
  statusLabel: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

NavLink.defaultProps = {
  hasChevron: false,
  icon: null,
  link: null,
  onClick: null,
  statusLabel: null,
  subtitle: null,
  title: null,
};
