import { useMemo } from "react";

import { getStoryblokNonProprietaryKeys } from "~utils/storyblok/storyblok";

export default function useSettingsClassNames(settings) {
  const classNames = useMemo(() => {
    if (!settings) return [];

    return settings.reduce((carry, component) => {
      // const groupKey = setting.component.replace("Settings", "");
      getStoryblokNonProprietaryKeys(component).forEach((key) => {
        const value = component[key];

        if (value?.plugin === "set-reset-responsive-value") {
          getStoryblokNonProprietaryKeys(value).forEach((k) => {
            const v = value[k];
            if (v) {
              const className = `${key}_${k}_${v}`;
              carry.push(className);
            }
          });
        } else if (value) {
          const className = `${key}_${value}`;
          carry.push(className);
        }
      });
      return carry;
    }, []);
  }, [settings]);

  return [classNames];
}
