import React, { useCallback, useState } from "react";

import PropTypes from "prop-types";
import SbEditable from "storyblok-react";

import ButtonTertiary from "~components/molecules/button/button_tertiary/button_tertiary";
import RichText from "~components/molecules/rich_text/rich_text";
import DebouncedFocusWrapper from "~components/utils/debounced_focus_wrapper/debounced_focus_wrapper";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import SVGIcon from "~components/utils/svg_icon/svg_icon";

import {
  LINK,
  RICH_TEXT,
  STORYBLOK_ENTRY_ARRAY,
} from "../../../../../../../prop_types";
import {
  ctaInnerStyle,
  ctaWrapperStyle,
  iconStyle,
} from "./nav_cta.module.scss";

function NavCTA({ icon: iconArray, text, linkText, link, ...rest }) {
  const [isActive, setIsActive] = useState(false);

  const [icon] = iconArray;

  const handleEnter = useCallback(() => {
    return setIsActive(true);
  }, []);

  const handleLeave = useCallback(() => {
    return setIsActive(false);
  }, []);

  return (
    <SbEditable content={rest}>
      <LinkWrapper link={link}>
        <DebouncedFocusWrapper
          {...{
            callbackWhenFocused: handleEnter,
            callbackWhenBlurred: handleLeave,
            enterDelay: 0,
            leaveDelay: 200,
          }}
        >
          <div className={ctaWrapperStyle}>
            <div className={ctaInnerStyle}>
              <div className={iconStyle}>
                {icon && <SVGIcon {...icon} width={32} height={32} />}
              </div>
              <RichText text={text} size="small" balanceText />
            </div>
            <ButtonTertiary
              title={linkText}
              trailingIcon="icon_chevron_right"
              isActive={isActive}
              color="azure"
              noWrap
            />
          </div>
        </DebouncedFocusWrapper>
      </LinkWrapper>
    </SbEditable>
  );
}

NavCTA.propTypes = {
  icon: STORYBLOK_ENTRY_ARRAY,
  text: RICH_TEXT,
  linkText: PropTypes.string,
  link: LINK,
};

NavCTA.defaultProps = {
  icon: null,
  text: null,
  linkText: null,
  link: null,
};

export default NavCTA;
