import React from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import SocialButton from "~components/atoms/social_button/social_button";
import Form from "~components/organisms/form/form";
import { BLOK_ARRAY } from "../../../../../prop_types";
import { socialButtonsWrapperStyle } from "./footer_newsletter_social_section.module.scss";

const GRID_SPLIT_NEWSLETTER_SOCIAL_REGULATORY = { XXL: 2, S: 1 };

export default function FooterNewsletterSocialSection({
  language,
  newsletterFormHeading,
  newsletterFormId,
  socialButtons,
  socialButtonsHeading,
}) {
  const formLabel = language
    ? `footer_newsletter_form_${language}`
    : "footer_newsletter_form";

  const shouldShowNewsletterForm = newsletterFormId && formLabel;

  return (
    <GridNew split={GRID_SPLIT_NEWSLETTER_SOCIAL_REGULATORY}>
      {/* ————— NEWSLETTER  ———— */}

      {shouldShowNewsletterForm && (
        <div
          key="newsletter-form"
          id="newsletter-form"
          data-cy="newsletter-form"
        >
          {newsletterFormHeading && (
            <Heading
              visualLevel={7}
              seoLevel={3}
              isUppercase
              color="cloudBurst"
            >
              {newsletterFormHeading}
            </Heading>
          )}
          <Form
            buttonColor="yellowTransparent"
            formConversionType="secondary_conversion"
            formId={newsletterFormId}
            formLabel={formLabel}
            lang={language}
            layout="singleLine"
          />
        </div>
      )}

      {/* ————— SOCIAL BUTTONS ———— */}

      {socialButtons?.length > 0 && (
        <div key="social-buttons" id="social-buttons" data-cy="social-buttons">
          {socialButtonsHeading && (
            <Heading
              visualLevel={7}
              seoLevel={3}
              isUppercase
              color="cloudBurst"
            >
              {socialButtonsHeading}
            </Heading>
          )}
          <div className={socialButtonsWrapperStyle}>
            {socialButtons.map((item) => {
              return <SocialButton key={item.link.url} {...item} />;
            })}
          </div>
        </div>
      )}
    </GridNew>
  );
}

FooterNewsletterSocialSection.defaultProps = {
  language: "en-gb",
  newsletterFormHeading: "",
  newsletterFormId: "",
  socialButtons: [],
  socialButtonsHeading: "",
};

FooterNewsletterSocialSection.propTypes = {
  language: PropTypes.string,
  newsletterFormHeading: PropTypes.string,
  newsletterFormId: PropTypes.string,
  socialButtons: BLOK_ARRAY,
  socialButtonsHeading: PropTypes.string,
};
