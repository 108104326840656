export default function gtagEventContactFormSubmission({
  formConversionType,
  formLabel,
  formId,
}) {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", "generate_lead", {
      conversion_type: formConversionType,
      form_label: formLabel,
      hs_form_id: formId,
    });
  }
}
