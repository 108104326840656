// extracted by mini-css-extract-plugin
export var borderStyle = "grid_item_new-module--borderStyle--OrF40";
export var gridItemStyle = "grid_item_new-module--gridItemStyle--lJvXt";
export var span_L_1 = "grid_item_new-module--span_L_1--Q2cYS";
export var span_L_2 = "grid_item_new-module--span_L_2--H6-Ny";
export var span_L_3 = "grid_item_new-module--span_L_3--xWOsh";
export var span_L_4 = "grid_item_new-module--span_L_4--gcGMR";
export var span_L_5 = "grid_item_new-module--span_L_5--26oWi";
export var span_L_6 = "grid_item_new-module--span_L_6--svlHm";
export var span_M_1 = "grid_item_new-module--span_M_1--E9BiN";
export var span_M_2 = "grid_item_new-module--span_M_2--r-PAW";
export var span_M_3 = "grid_item_new-module--span_M_3--T+f3M";
export var span_M_4 = "grid_item_new-module--span_M_4--M9NOI";
export var span_M_5 = "grid_item_new-module--span_M_5--D4TCz";
export var span_M_6 = "grid_item_new-module--span_M_6--wcU0u";
export var span_S_1 = "grid_item_new-module--span_S_1--kf1ap";
export var span_S_2 = "grid_item_new-module--span_S_2--1C3Ni";
export var span_S_3 = "grid_item_new-module--span_S_3--7I1d9";
export var span_S_4 = "grid_item_new-module--span_S_4--3L5wf";
export var span_S_5 = "grid_item_new-module--span_S_5--0fFTo";
export var span_S_6 = "grid_item_new-module--span_S_6--a12HO";
export var span_XL_1 = "grid_item_new-module--span_XL_1--dmzvd";
export var span_XL_2 = "grid_item_new-module--span_XL_2--05LZG";
export var span_XL_3 = "grid_item_new-module--span_XL_3--ni3Bg";
export var span_XL_4 = "grid_item_new-module--span_XL_4--r2TMw";
export var span_XL_5 = "grid_item_new-module--span_XL_5--b9ntd";
export var span_XL_6 = "grid_item_new-module--span_XL_6--o81FX";
export var span_XS_1 = "grid_item_new-module--span_XS_1--kHbiL";
export var span_XS_2 = "grid_item_new-module--span_XS_2--zezn1";
export var span_XS_3 = "grid_item_new-module--span_XS_3--OuICQ";
export var span_XS_4 = "grid_item_new-module--span_XS_4--0AHou";
export var span_XS_5 = "grid_item_new-module--span_XS_5--1hFyb";
export var span_XS_6 = "grid_item_new-module--span_XS_6--2RZgK";
export var span_XXL_1 = "grid_item_new-module--span_XXL_1--WfznF";
export var span_XXL_2 = "grid_item_new-module--span_XXL_2--BuWG6";
export var span_XXL_3 = "grid_item_new-module--span_XXL_3--ODZ2k";
export var span_XXL_4 = "grid_item_new-module--span_XXL_4--xLgTR";
export var span_XXL_5 = "grid_item_new-module--span_XXL_5--+sVEM";
export var span_XXL_6 = "grid_item_new-module--span_XXL_6--AaL8Y";
export var span_XXS_1 = "grid_item_new-module--span_XXS_1--lWnFx";
export var span_XXS_2 = "grid_item_new-module--span_XXS_2--dXl6g";
export var span_XXS_3 = "grid_item_new-module--span_XXS_3--eKwgE";
export var span_XXS_4 = "grid_item_new-module--span_XXS_4--IjiPy";
export var span_XXS_5 = "grid_item_new-module--span_XXS_5--yrEdw";
export var span_XXS_6 = "grid_item_new-module--span_XXS_6--1W0XA";