import PropTypes from "prop-types";
import React from "react";

import Image from "~components/atoms/image/image";

import { MEDIA } from "../../../../../../../prop_types";
import {
  iconStyle,
  languageSelectButtonStyle,
} from "./language_select_button.module.scss";

function LanguageSelectButton({ onClick, currentLanguage }) {
  return (
    <button
      aria-label="Language select"
      title="Language select"
      onClick={onClick}
      className={languageSelectButtonStyle}
      type="button"
    >
      <div className={iconStyle}>
        <Image image={currentLanguage?.icon} isFullWidth />
      </div>
    </button>
  );
}

LanguageSelectButton.propTypes = {
  onClick: PropTypes.func,
  currentLanguage: PropTypes.shape({ icon: MEDIA }).isRequired,
};

LanguageSelectButton.defaultProps = {
  onClick: null,
};

export default LanguageSelectButton;
