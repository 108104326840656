import React, { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import MenuButtonIcon from "~components/organisms/header/header_components/mobile_nav/mobile_nav_components/menu_button_icon/menu_button_icon";
import TransitionComponent from "~components/utils/transition/transition";
import useLockedBody from "~hooks/use_locked_body/use_locked_body";
import useMatchMedia from "~hooks/use_match_media/use_match_media";
import HideOnScrollContext from "../../../../../hide_on_scroll_context";
import { BLOK_ARRAY } from "../../../../../prop_types";
import {
  adjustHeightForHeaderAndBannerStyle,
  adjustHeightForHeaderStyle,
  adjustTopForHeaderStyle,
  mobileMenuDropdownInnerStyle,
  mobileMenuDropdownWrapperStyle,
} from "./mobile_nav.module.scss";
import MobileNavLeftMenu from "./mobile_nav_components/mobile_nav_left_menu/mobile_nav_left_menu";
import MobileNavRightMenu from "./mobile_nav_components/mobile_nav_right_menu/mobile_nav_right_menu";

const TRANSITION_PROPS = {
  appear: true,
  unmountOnExit: true,
  timeout: {
    appear: 0,
    enter: 200,
    exit: 400,
  },
};

export default function MobileNav({
  items,
  color,
  callbackOnMobileMenuOpen,
  isLanguageSelectOpen,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const isDesktopWindow = useMatchMedia({ min: "XL" });
  const { isScrolledToTop, shouldHideHeader } = useContext(HideOnScrollContext);

  const mobileMenuWrapperClassNames = classNames(
    mobileMenuDropdownWrapperStyle,
    {
      [adjustTopForHeaderStyle]: !shouldHideHeader,
    }
  );
  const mobileMenuInnerClassNames = classNames(mobileMenuDropdownInnerStyle, {
    [adjustHeightForHeaderStyle]: !shouldHideHeader,
    [adjustHeightForHeaderAndBannerStyle]: isScrolledToTop,
  });

  // lock body scroll when menu is open
  const setLocked = useLockedBody();

  const toggleMobileMenu = useCallback(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      callbackOnMobileMenuOpen(true);
      setLocked(true);
    } else {
      setIsMenuOpen(false);
      setLocked(false);
    }
  }, [callbackOnMobileMenuOpen, isMenuOpen, setLocked]);

  useEffect(() => {
    if (isDesktopWindow) {
      setIsMenuOpen(false);
      setLocked(false);
    }
  }, [isDesktopWindow, setLocked]);

  const callbackOnTransitionEnter = useCallback(() => {
    return callbackOnMobileMenuOpen(true);
  }, [callbackOnMobileMenuOpen]);

  const callbackOnTransitionExited = useCallback(() => {
    return callbackOnMobileMenuOpen(false);
  }, [callbackOnMobileMenuOpen]);

  useEffect(() => {
    if (isLanguageSelectOpen) {
      setIsMenuOpen(false);
      callbackOnMobileMenuOpen(false);
    }
  }, [isLanguageSelectOpen, callbackOnMobileMenuOpen]);

  return (
    <>
      <MenuButtonIcon
        color={color}
        isActive={isMenuOpen}
        onClick={toggleMobileMenu}
      />

      <div className={mobileMenuWrapperClassNames}>
        <TransitionComponent
          {...TRANSITION_PROPS}
          isActive={isMenuOpen}
          transition="slideDownMenu"
          onEnter={callbackOnTransitionEnter}
          onExited={callbackOnTransitionExited}
        >
          <div className={mobileMenuInnerClassNames}>
            <MobileNavLeftMenu
              activeGroupIndex={activeGroupIndex}
              isMenuOpen={isMenuOpen}
              items={items}
              setActiveGroupIndex={setActiveGroupIndex}
              toggleMobileMenu={toggleMobileMenu}
            />

            <MobileNavRightMenu
              activeGroupIndex={activeGroupIndex}
              callbackOnClickItem={toggleMobileMenu}
              isMenuOpen={isMenuOpen}
              items={items}
            />
          </div>
        </TransitionComponent>
      </div>
    </>
  );
}

MobileNav.propTypes = {
  items: BLOK_ARRAY,
  color: PropTypes.oneOf([
    "yellow",
    "white",
    "blue",
    "yellowTransparent",
    "whiteTransparent",
  ]).isRequired,
  callbackOnMobileMenuOpen: PropTypes.func,
  isLanguageSelectOpen: PropTypes.bool,
};

MobileNav.defaultProps = {
  items: null,
  callbackOnMobileMenuOpen: null,
  isLanguageSelectOpen: false,
};
