import { graphql, useStaticQuery } from "gatsby";

export default function useSiteUrl() {
  const { site } = useStaticQuery(graphql`
    query SiteURLQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  return site.siteMetadata.siteUrl;
}
