import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";

export default function useDebouncedHoverInteraction() {
  const [activeItemState, setActiveItemState] = useState({
    currentStep: null,
    prevStep: null,
  });

  const { currentStep, prevStep } = activeItemState;

  const setActiveItem = useCallback((index) => {
    return setActiveItemState((currentState) => {
      // if no index passed, it is to close the menu
      if (typeof index !== "number") {
        return {
          prevStep: null,
          currentStep: null,
        };
      }
      // when we first enter the nav, prevStep will be null
      // set prevStep to 0 so we can use it in comparison logic elsewhere
      if (currentState.prevStep === null) {
        return {
          prevStep: index,
          currentStep: index,
        };
      }
      if (index !== currentState.currentStep) {
        return {
          prevStep: currentState.currentStep,
          currentStep: index,
        };
      }
      return currentState;
    });
  }, []);

  // Best practice is to wrap time based functions in useRef
  // https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  const debouncedSetActiveItem = useRef(
    debounce((index) => {
      return setActiveItem(index);
    }, 250)
  );

  const abandonDebouncedSetActiveItem = () => {
    debouncedSetActiveItem.current.cancel();
  };

  // clean up any `setInactive` on unmount
  useEffect(() => {
    return debouncedSetActiveItem.current.cancel();
  }, []);

  return {
    currentStep,
    prevStep,
    debouncedSetActiveItem: debouncedSetActiveItem.current,
    setActiveItem,
    abandonDebouncedSetActiveItem,
  };
}
