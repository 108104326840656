import { useEffect } from "react";

const events = [`mousedown`, `touchstart`];
export default function useClickOutside(ref, onClickOutside) {
  useEffect(() => {
    const isOutside = (element) => {
      return !ref.current || !ref.current.contains(element);
    };
    const onClick = (event) => {
      if (isOutside(event.target)) {
        onClickOutside();
      }
    };
    events.map((eventToListenTo) => {
      return document.addEventListener(eventToListenTo, onClick);
    });

    return () => {
      events.map((eventToListenTo) => {
        return document.removeEventListener(eventToListenTo, onClick);
      });
    };
  });
}
