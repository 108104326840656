// extracted by mini-css-extract-plugin
export var blueTextStyle = "header-module--blueTextStyle--Y9qcP";
export var header = "header-module--header--l5qfh";
export var headerInner = "header-module--headerInner--M80VF";
export var headerLeftStyle = "header-module--headerLeftStyle--CgE8Q";
export var headerRightStyle = "header-module--headerRightStyle--NVUMI";
export var headerWrapperStyle = "header-module--headerWrapperStyle--pS7IF";
export var languageSelectWrapperStyle = "header-module--languageSelectWrapperStyle--JVQLX";
export var shadowStyle = "header-module--shadowStyle--nojhj";
export var whiteBackgroundStyle = "header-module--whiteBackgroundStyle--FjbFJ";
export var whiteTextStyle = "header-module--whiteTextStyle--bzpJn";