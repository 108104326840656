import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { getLinkProps } from "~utils/link_utils/getLinkProps/getLinkProps";
import { LINK } from "../../../prop_types";
import {
  azureStyle,
  grayStyle,
  isActiveTabStyle,
  isCategoryStyle,
  isInteractiveStyle,
  mdStyle,
  smStyle,
  tagStyle,
  whiteTransparentStyle,
  xsStyle,
} from "./tag.module.scss";

function Tag({
  link,
  title,
  isActive,
  onClick,
  isCategory,
  isPrimary,
  size,
  color,
}) {
  const linkProps = getLinkProps(link);

  const tagClassNames = classNames(tagStyle, {
    [mdStyle]: size === "md",
    [smStyle]: size === "sm",
    [xsStyle]: size === "xs",
    [azureStyle]: color === "azure",
    [grayStyle]: color === "gray",
    [whiteTransparentStyle]: color === "whiteTransparent",

    [isCategoryStyle]: isPrimary || isCategory,
    [isActiveTabStyle]: isActive,
    [isInteractiveStyle]: link || onClick,
  });

  if (onClick) {
    return (
      <button className={tagClassNames} onClick={onClick} type="button">
        {title}
      </button>
    );
  }
  if (link) {
    return (
      <Link data-cy="tag_link" className={tagClassNames} {...linkProps}>
        {title}
      </Link>
    );
  }

  return (
    <div className={tagClassNames} type="button">
      {title}
    </div>
  );
}

Tag.defaultProps = {
  link: null,
  isCategory: false,
  isTag: false,
  isActive: false,
  onClick: null,
  basePath: null,
  isPrimary: false,
  size: "md",
  color: "gray",
};

Tag.propTypes = {
  link: LINK,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  isCategory: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isTag: PropTypes.bool,
  basePath: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Tag;
