export default function gtagEventRoastAgencyConversion() {
  if (typeof window !== "undefined" && window.gtag) {
    // Send event to Roast's google campaign manager
    // Roast are our paid media agency
    window.gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-11060861/conve0/truel0+unique",
    });
  }
}
