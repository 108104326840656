import { useCallback, useEffect, useState } from "react";
import getMediaQuery from "./helper_functions/get_media_query";

export default function useMatchMedia({ min, max }) {
  const query = getMediaQuery({ min, max });

  // Defaults to false until matchMedia has changed
  const [doesMatch, setDoesMatch] = useState(false);

  const handleChange = useCallback(({ matches }) => {
    setDoesMatch(matches);
  }, []);

  // Create matchMedia singleton if doesn't exist yet
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // if (typeof window !== "undefined") {
    const matcher = window.matchMedia(query);
    matcher.addEventListener("change", handleChange);
    setDoesMatch(matcher.matches);

    return () => {
      matcher.removeEventListener("change", handleChange);
    };
    // }
  }, [handleChange, query]);

  return doesMatch;
}
