import React, { useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Interactive } from "react-interactive";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import useComponentMargin from "~hooks/use_component_margin/use_component_margin";
import useHandleInteractiveStateChange from "~hooks/use_handle_interactive_state_change/use_handle_interactive_state_change";
import { BLOK_ARRAY, LINK } from "../../../../prop_types";
import ButtonInnerContent from "../button_components/button_inner_content/button_inner_content";
import {
  buttonStyle,
  charcoalTransparentStyle,
  fullWidthStyle,
  grayTransparentStyle,
  isActiveStyle,
  largeStyle,
  mediumStyle,
  smallStyle,
  squareStyle,
  whiteStyle,
  whiteTransparentStyle,
  wrapperCenteredStyle,
  yellowStyle,
  yellowTransparentStyle,
} from "./button.module.scss";

export default function Button({
  children,
  color,
  hasChevron,
  id,
  isActive,
  isCentered,
  isDisabled,
  isFullWidth,
  link,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  onClick,
  size,
  text,
  testId,
}) {
  const [localActiveState, handleInteractiveStateChange] =
    useHandleInteractiveStateChange();

  const trailingIcon = hasChevron ? "icon_chevron_right" : null;

  const buttonClassNames = useMemo(() => {
    return classNames(buttonStyle, {
      [fullWidthStyle]: isFullWidth,
      [grayTransparentStyle]: color === "grayTransparent",
      [isActiveStyle]: isActive || localActiveState,
      [largeStyle]: size === "large",
      [mediumStyle]: size === "medium",
      [smallStyle]: size === "small",
      [squareStyle]: size === "square",
      [whiteStyle]: color === "white",
      [whiteTransparentStyle]: color === "whiteTransparent",
      [wrapperCenteredStyle]: isCentered,
      [yellowStyle]: color === "yellow",
      [yellowTransparentStyle]: color === "yellowTransparent",
      [charcoalTransparentStyle]: color === "charcoalTransparent",
    });
  }, [color, isActive, isCentered, isFullWidth, localActiveState, size]);

  const wrapperMarginStyles = useComponentMargin({
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
  });

  const commonProps = useMemo(() => {
    return {
      "data-testid": testId,
      name: id,
      className: buttonClassNames,
      style: wrapperMarginStyles,
    };
  }, [buttonClassNames, id, testId, wrapperMarginStyles]);

  if (link) {
    return (
      <Interactive
        as={LinkWrapper}
        link={link}
        onStateChange={handleInteractiveStateChange}
        {...commonProps}
      >
        <ButtonInnerContent
          {...{
            title: children || text,
            trailingIcon,
            isActive: isActive || localActiveState,
          }}
        />
      </Interactive>
    );
  }

  if (onClick) {
    return (
      <Interactive
        as="button"
        onClick={onClick}
        disabled={isDisabled}
        onStateChange={handleInteractiveStateChange}
        {...commonProps}
      >
        <ButtonInnerContent
          {...{
            title: children || text,
            trailingIcon,
            isActive: isActive || localActiveState,
          }}
        />
      </Interactive>
    );
  }

  return (
    <div {...commonProps}>
      <ButtonInnerContent
        {...{
          title: children || text,
          trailingIcon,
          isActive: isActive || localActiveState,
        }}
      />
    </div>
  );
}

Button.defaultProps = {
  chevronType: null,
  children: null,
  color: "yellow",
  hasChevron: false,
  icon: null,
  id: null,
  isActive: false,
  isCentered: false,
  isDisabled: false,
  isFullWidth: false,
  link: null,
  marginBottom: null,
  marginLeft: null,
  marginRight: null,
  marginTop: null,
  onClick: null,
  size: "medium",
  text: null,
  testId: "button",
};

Button.propTypes = {
  chevronType: PropTypes.oneOf([
    "chevronRight",
    "chevronDown",
    "chevronExternalLink",
  ]),
  children: PropTypes.node,
  color: PropTypes.oneOf([
    "yellow",
    "white",
    "yellowTransparent",
    "whiteTransparent",
    "grayTransparent",
    "charcoalTransparent",
  ]),
  hasChevron: PropTypes.bool,
  icon: BLOK_ARRAY,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isCentered: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  link: LINK,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large", "square"]),
  text: PropTypes.string,
  testId: PropTypes.string,
};
