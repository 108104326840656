import PropTypes from "prop-types";
import React from "react";

import GridNew from "~components/atoms/grid_new/grid_new";
import SVGIcon from "~components/utils/svg_icon/svg_icon";

import {
  footerSectionStyle,
  regulatoryDisclaimerTextStyle,
} from "./footer_logo_regulatory_section.module.scss";

const GRID_SPLIT = { XXL: 2, M: 1 };

export default function FooterLogoRegulatorySection({ regulatoryDisclaimer }) {
  return (
    <div className={footerSectionStyle}>
      <GridNew split={GRID_SPLIT}>
        <SVGIcon
          icon="icon_truelayer_large"
          width={64}
          height={64}
          color="cloudBurst10"
          key="icon"
        />
        <div key="disclaimer" className={regulatoryDisclaimerTextStyle}>
          {regulatoryDisclaimer}
        </div>
      </GridNew>
    </div>
  );
}

FooterLogoRegulatorySection.defaultProps = {
  regulatoryDisclaimer: "",
};

FooterLogoRegulatorySection.propTypes = {
  regulatoryDisclaimer: PropTypes.string,
};
