import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Image from "~components/atoms/image/image";
import { getLinkProps } from "~utils/link_utils/getLinkProps/getLinkProps";
import { LINK, MEDIA } from "../../../../../../../prop_types";
import * as classes from "./language_item.module.scss";

export default function LanguageItem({
  icon,
  link,
  title,
  isSelected,
  callbackOnClick,
}) {
  if (isSelected) {
    return (
      <div className={classes.selectedLanguageItem}>
        {icon?.filename && (
          <div className={classes.icon}>
            <Image image={icon} isFullWidth />
          </div>
        )}
        <div className={classes.title}>{title || "Language"}</div>
      </div>
    );
  }

  return (
    <Link
      className={classes.languageItem}
      onClick={callbackOnClick}
      {...getLinkProps(link)}
    >
      {icon?.filename && (
        <div className={classes.icon}>
          <Image image={icon} isFullWidth />
        </div>
      )}
      <div className={classes.title}>{title || "Language"}</div>
    </Link>
  );
}

LanguageItem.defaultProps = {
  title: "",
  icon: null,
  link: null,
  isSelected: false,
  callbackOnClick: () => {},
};

LanguageItem.propTypes = {
  title: PropTypes.string,
  icon: MEDIA,
  link: LINK,
  isSelected: PropTypes.bool,
  callbackOnClick: PropTypes.func,
};
