import PropTypes from "prop-types";
import React from "react";
import { Transition } from "react-transition-group";

import * as classes from "./transition.module.scss";

function TransitionComponent({
  children,
  isActive,
  transition,
  unmountOnExit,
  timeout,
  onEnter,
  onExited,
  appear,
  stretch,
}) {
  return (
    <Transition
      appear={appear}
      in={isActive}
      onEnter={onEnter}
      onExited={onExited}
      timeout={timeout}
      unmountOnExit={unmountOnExit}
    >
      {(state) => {
        const classNames = [
          classes[`${transition}`],
          classes[`${transition}_${state}`],
        ];
        if (stretch) {
          classNames.push(classes.stretch);
        }
        return <div className={classNames.join(" ")}>{children}</div>;
      }}
    </Transition>
  );
}

TransitionComponent.defaultProps = {
  appear: false,
  isActive: false,
  onEnter: () => {},
  onExited: () => {},
  stretch: false,
  timeout: {
    appear: 0,
    enter: 0,
    exit: 300,
  },
  transition: "fade",
  unmountOnExit: false,
};

TransitionComponent.propTypes = {
  appear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  stretch: PropTypes.bool,
  timeout: PropTypes.shape({
    appear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    enter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    exit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  transition: PropTypes.oneOf([
    "fade",
    "fadeRight",
    "slide",
    "slideUp",
    "slideRight",
    "slideRightMenu",
    "slideRightForm",
    "slideLeftForm",
    "slideLeftMenu",
    "slideDown",
    "slideDownMenu",
  ]),
  unmountOnExit: PropTypes.bool,
};

export default TransitionComponent;
