import React, { useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Interactive } from "react-interactive";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import useHandleInteractiveStateChange from "~hooks/use_handle_interactive_state_change/use_handle_interactive_state_change";
import { LINK } from "../../../../prop_types";
import ButtonInnerContent from "../button_components/button_inner_content/button_inner_content";
import {
  buttonStyle,
  colorAzureStyle,
  colorCloudBurst50Style,
  colorCloudBurst80Style,
  colorCloudBurstStyle,
  colorSunStyle,
  colorWhiteStyle,
  isActiveStyle,
  isCenteredStyle,
  isDisabledStyle,
  isFontBoldStyle,
  isFontRegularStyle,
  isInteractiveStyle,
  isUnderlinedStyle,
  isUpperCaseStyle,
  marginBottomLgStyle,
  marginBottomMdStyle,
  marginBottomNoneStyle,
  marginBottomSmStyle,
  marginBottomXsStyle,
  marginLeftLgStyle,
  marginLeftMdStyle,
  marginLeftNoneStyle,
  marginLeftSmStyle,
  marginLeftXsStyle,
  marginRightLgStyle,
  marginRightMdStyle,
  marginRightNoneStyle,
  marginRightSmStyle,
  marginRightXsStyle,
  marginTopLgStyle,
  marginTopMdStyle,
  marginTopNoneStyle,
  marginTopSmStyle,
  marginTopXsStyle,
  noWrapStyle,
  spaceBetweenStyle,
} from "./button_tertiary.module.scss";

function ButtonTertiary({
  color,
  isActive,
  isCentered,
  isDisabled,
  isFontBold,
  isFontRegular,
  isInteractive,
  isUnderlined,
  isUppercase,
  leadingIcon,
  link,
  margin,
  noWrap,
  onClick,
  spaceBetween,
  title,
  trailingIcon,
  ...rest
}) {
  const [localActiveState, handleInteractiveStateChange] =
    useHandleInteractiveStateChange();

  const { "data-testid": testId } = rest;

  const buttonClassNames = classNames(buttonStyle, {
    [isActiveStyle]: isActive || localActiveState,
    [isDisabledStyle]: isDisabled,
    /* ——————————————————————————————————————————————————————————————
    //      OVERRIDE STYLES                                           
    // —————————————————————————————————————————————————————————————— */
    [isFontBoldStyle]: isFontBold,
    [spaceBetweenStyle]: spaceBetween,
    [isCenteredStyle]: isCentered,
    [noWrapStyle]: noWrap,
    [isFontRegularStyle]: isFontRegular,
    [isUnderlinedStyle]: isUnderlined,
    [isUpperCaseStyle]: isUppercase,
    [isInteractiveStyle]: isInteractive,
    /* ——————————————————————————————————————————————————————————————
    //      COLOR STYLES                                           
    // —————————————————————————————————————————————————————————————— */
    [colorAzureStyle]: color === "azure",
    [colorCloudBurst50Style]: color === "cloudBurst50",
    [colorCloudBurst80Style]: color === "cloudBurst80",
    [colorCloudBurstStyle]: color === "cloudBurst",
    [colorSunStyle]: color === "yellow",
    [colorWhiteStyle]: color === "white",

    /* ——————————————————————————————————————————————————————————————
    //      MARGIN STYLES                                           
    // —————————————————————————————————————————————————————————————— */
    // Using native scss modules plays better with SSR and reduces layout shift
    // Allowing per-component margin styles rather than a global style to allow granular control

    /* ——————————————————————————————————————————————
    //      MARGIN TOP STYLES                       
    // —————————————————————————————————————————————— */
    [marginTopLgStyle]: margin.marginTop === "lg",
    [marginTopMdStyle]: margin.marginTop === "md",
    [marginTopSmStyle]: margin.marginTop === "sm",
    [marginTopXsStyle]: margin.marginTop === "xs",
    [marginTopNoneStyle]: margin.marginTop === "none",
    /* ——————————————————————————————————————————————
    //      MARGIN BOTTOM STYLES                       
    // —————————————————————————————————————————————— */
    [marginBottomLgStyle]: margin.marginBottom === "lg",
    [marginBottomMdStyle]: margin.marginBottom === "md",
    [marginBottomSmStyle]: margin.marginBottom === "sm",
    [marginBottomXsStyle]: margin.marginBottom === "xs",
    [marginBottomNoneStyle]: margin.marginBottom === "none",
    /* ——————————————————————————————————————————————
    //      MARGIN LEFT STYLES                       
    // —————————————————————————————————————————————— */
    [marginLeftLgStyle]: margin.marginLeft === "lg",
    [marginLeftMdStyle]: margin.marginLeft === "md",
    [marginLeftSmStyle]: margin.marginLeft === "sm",
    [marginLeftXsStyle]: margin.marginLeft === "xs",
    [marginLeftNoneStyle]: margin.marginLeft === "none",
    /* ——————————————————————————————————————————————
    //      MARGIN RIGHT STYLES                       
    // —————————————————————————————————————————————— */
    [marginRightLgStyle]: margin.marginRight === "lg",
    [marginRightMdStyle]: margin.marginRight === "md",
    [marginRightSmStyle]: margin.marginRight === "sm",
    [marginRightXsStyle]: margin.marginRight === "xs",
    [marginRightNoneStyle]: margin.marginRight === "none",
  });

  const commonProps = useMemo(() => {
    return {
      className: buttonClassNames,
      onStateChange: handleInteractiveStateChange,
      "data-testid": testId,
    };
  }, [buttonClassNames, handleInteractiveStateChange, testId]);

  if (link) {
    return (
      <Interactive
        as={LinkWrapper}
        disabled={isDisabled}
        link={link}
        {...commonProps}
      >
        <ButtonInnerContent
          {...{
            leadingIcon,
            title,
            trailingIcon,
            isActive: isActive || localActiveState,
          }}
        />
      </Interactive>
    );
  }
  if (onClick) {
    return (
      <Interactive
        as="button"
        onClick={onClick}
        disabled={isDisabled}
        {...commonProps}
      >
        <ButtonInnerContent
          {...{
            leadingIcon,
            title,
            trailingIcon,
            isActive: isActive || localActiveState,
          }}
        />
      </Interactive>
    );
  }
  return (
    <Interactive disabled={isDisabled} as="div" {...commonProps}>
      <ButtonInnerContent
        {...{
          leadingIcon,
          title,
          trailingIcon,
          isActive: isActive || localActiveState,
        }}
      />
    </Interactive>
  );
}

ButtonTertiary.propTypes = {
  color: PropTypes.string,
  isActive: PropTypes.bool,
  isCentered: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFontBold: PropTypes.bool,
  isFontRegular: PropTypes.bool,
  isInteractive: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  isUppercase: PropTypes.bool,
  leadingIcon: PropTypes.string,
  link: LINK,
  margin: PropTypes.shape({
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
  }),
  noWrap: PropTypes.bool,
  onClick: PropTypes.func,
  spaceBetween: PropTypes.bool,
  title: PropTypes.string.isRequired,
  trailingIcon: PropTypes.string,
};
ButtonTertiary.defaultProps = {
  color: "cloudBurst80",
  isFontBold: false,
  isFontRegular: false,
  isActive: false,
  isCentered: false,
  noWrap: false,
  margin: {
    marginTop: "sm",
    marginBottom: "sm",
    marginLeft: "none",
    marginRight: "none",
  },
  isDisabled: false,
  isUppercase: false,
  isUnderlined: false,
  leadingIcon: null,
  link: null,
  onClick: null,
  trailingIcon: null,
  spaceBetween: false,
  isInteractive: true,
};

export default ButtonTertiary;
