import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY, RESPONSIVE_VALUE_NEW } from "../../../prop_types";
import Image from "../image/image";
import * as classes from "./grid_new.module.scss";

const CHILDREN_COMPONENTS = {
  image: Image,
};

export default function GridNew({
  children,
  hasColumnGap,
  hasRowGap,
  isStoryblok,
  settings,
  split,
  stretch,
  ...rest
}) {
  const [settingsClassNames] = useSettingsClassNames(settings);
  const classNames = [classes.grid, ...settingsClassNames];

  if (hasRowGap) {
    classNames.push(classes.rowGapStyle);
  }
  if (hasColumnGap) {
    classNames.push(classes.columnGapStyle);
  }
  if (stretch) {
    classNames.push(classes.stretch);
  }

  if (!Array.isArray(children)) {
    return children;
  }

  if (split) {
    ["XXL", "XL", "L", "M", "S", "XS", "XXS"].forEach((breakpoint) => {
      const value = split[breakpoint];
      if (value) {
        classNames.push(classes[`split_${breakpoint}_${value}`]);
      }
    });
  }

  return (
    <SbEditable content={rest}>
      <div className={classNames.join(" ")}>
        {isStoryblok
          ? children.map((childComponent) => {
              return renderDynamicComponent({
                parentComponentName: "GridNew",
                componentName: childComponent.component,
                componentsMap: CHILDREN_COMPONENTS,
                ...childComponent,
              });
            })
          : children}
      </div>
    </SbEditable>
  );
}

GridNew.defaultProps = {
  children: null,
  hasColumnGap: true,
  hasRowGap: true,
  isStoryblok: false,
  settings: null,
  split: null,
  stretch: false,
};

GridNew.propTypes = {
  children: PropTypes.oneOfType([BLOK_ARRAY, PropTypes.node]),
  hasColumnGap: PropTypes.bool,
  hasRowGap: PropTypes.bool,
  isStoryblok: PropTypes.bool,
  settings: BLOK_ARRAY,
  split: RESPONSIVE_VALUE_NEW,
  stretch: PropTypes.bool,
};
