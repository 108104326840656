import PropTypes from "prop-types";
import React from "react";
import { CSSTransition } from "react-transition-group";

import {
  fadeAppearActiveStyle,
  fadeAppearStyle,
  fadeEnterActiveStyle,
  fadeEnterStyle,
  fadeExitActiveStyle,
  fadeExitStyle,
} from "./css_transition_component.module.scss";

function CSSTransitionComponent({
  children,
  isActive,
  unmountOnExit,
  timeout,
  onEnter,
  onExited,
  appear,
  classNames,
}) {
  return (
    <CSSTransition
      classNames={classNames}
      appear={appear}
      in={isActive}
      onEnter={onEnter}
      onExited={onExited}
      timeout={timeout}
      unmountOnExit={unmountOnExit}
    >
      {children}
    </CSSTransition>
  );
}

CSSTransitionComponent.defaultProps = {
  appear: false,
  classNames: {
    appear: fadeAppearStyle,
    appearActive: fadeAppearActiveStyle,
    appearDone: {},
    enter: fadeEnterStyle,
    enterActive: fadeEnterActiveStyle,
    enterDone: {},
    exit: fadeExitStyle,
    exitActive: fadeExitActiveStyle,
    exitDone: {},
  },
  isActive: false,
  onEnter: () => {},
  onExited: () => {},
  timeout: {
    appear: 0,
    enter: 200,
    exit: 200,
  },
  unmountOnExit: false,
};

CSSTransitionComponent.propTypes = {
  appear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  classNames: PropTypes.shape({
    appear: PropTypes.string,
    appearActive: PropTypes.string,
    appearDone: PropTypes.string,
    enter: PropTypes.string,
    enterActive: PropTypes.string,
    enterDone: PropTypes.string,
    exit: PropTypes.string,
    exitActive: PropTypes.string,
    exitDone: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  timeout: PropTypes.shape({
    appear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    enter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    exit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),

  unmountOnExit: PropTypes.bool,
};

export default CSSTransitionComponent;
