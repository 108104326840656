/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React from "react";

import CSSTransitionComponent from "~components/utils/css_transition_component/css_transition_component";

import { menuWrapperStyle } from "./desktop_nav_dropdown_inner.module.scss";
import {
  transitionFade_enter,
  transitionFade_enter_active,
  transitionFade_enter_done,
  transitionFade_exit,
  transitionFade_exit_active,
  transitionFade_exit_done,
} from "./desktop_nav_dropdown_inner_transitions.module.scss";

const TRANSITION_CLASSNAMES = {
  enter: transitionFade_enter,
  enterActive: transitionFade_enter_active,
  enterDone: transitionFade_enter_done,
  exit: transitionFade_exit,
  exitActive: transitionFade_exit_active,
  exitDone: transitionFade_exit_done,
};

const TIMEOUT = {
  appear: 0,
  enter: 200,
  exit: 200,
};
export default function DesktopNavDropdownInner({ children, isActive }) {
  return (
    <CSSTransitionComponent
      isActive={isActive}
      classNames={TRANSITION_CLASSNAMES}
      timeout={TIMEOUT}
    >
      <div className={menuWrapperStyle}>{children}</div>
    </CSSTransitionComponent>
  );
}

DesktopNavDropdownInner.defaultProps = {
  children: null,
  isActive: false,
};

DesktopNavDropdownInner.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
};
