import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import DesktopNavDropdownWrapper from "~components/organisms/header/header_components/desktop_nav/desktop_nav_components/desktop_nav_dropdown_wrapper/desktop_nav_dropdown_wrapper";
import DebouncedFocusWrapper from "~components/utils/debounced_focus_wrapper/debounced_focus_wrapper";
import { BLOK_ARRAY } from "../../../../../prop_types";
import DesktopNavDropdownInner from "../desktop_nav/desktop_nav_components/desktop_nav_dropdown_inner/desktop_nav_dropdown_inner";
import LanguageItem from "./language_select_components/language_item/language_item";
import LanguageSelectButton from "./language_select_components/language_select_button/language_select_button";

const GRID_SPLIT = { XXL: "3", M: "2", XS: "1" };

export default function LanguageSelect({
  languages,
  callbackOnToggle,
  isOtherSubMenuOpen,
}) {
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);

  const closeMenu = useCallback(() => {
    setShowLanguageSelect(false);
  }, []);

  const toggleMenu = useCallback(() => {
    setShowLanguageSelect((current) => {
      return !current;
    });
  }, []);

  const callbackOnDropdownEnter = useCallback(() => {
    return callbackOnToggle(true);
  }, [callbackOnToggle]);

  const callbackOnDropdownExit = useCallback(() => {
    return callbackOnToggle(false);
  }, [callbackOnToggle]);

  useEffect(() => {
    if (isOtherSubMenuOpen) {
      closeMenu();
    }
  }, [closeMenu, isOtherSubMenuOpen]);

  const currentLanguage =
    languages &&
    languages.find((language) => {
      return language.isCurrent;
    });

  return (
    <>
      <LanguageSelectButton
        onClick={toggleMenu}
        currentLanguage={currentLanguage}
      />
      <DesktopNavDropdownWrapper
        isActive={showLanguageSelect}
        callbackOnOpenSubMenu={callbackOnDropdownEnter}
        callbackOnCloseSubMenu={callbackOnDropdownExit}
      >
        <DebouncedFocusWrapper callbackWhenBlurred={closeMenu} leaveDelay={500}>
          <DesktopNavDropdownInner
            onBlur={toggleMenu}
            onMouseLeave={toggleMenu}
            isActive={showLanguageSelect}
          >
            {languages && languages.length > 0 && (
              <GridNew split={GRID_SPLIT}>
                {languages.map((item) => {
                  return (
                    <LanguageItem
                      {...item}
                      key={item._uid}
                      callbackOnClick={toggleMenu}
                    />
                  );
                })}
              </GridNew>
            )}
          </DesktopNavDropdownInner>
        </DebouncedFocusWrapper>
      </DesktopNavDropdownWrapper>
    </>
  );
}

LanguageSelect.defaultProps = {
  languages: null,
  isOtherSubMenuOpen: false,
  callbackOnToggle: null,
};

LanguageSelect.propTypes = {
  languages: BLOK_ARRAY,
  isOtherSubMenuOpen: PropTypes.bool,
  callbackOnToggle: PropTypes.func,
};
