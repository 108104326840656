import PropTypes from "prop-types";
import React from "react";

import SVGIcon from "~components/utils/svg_icon/svg_icon";

import { leadingIconStyle } from "./button_leading_icon.module.scss";

export default function ButtonLeadingIcon({ icon, iconSize, color, isActive }) {
  return (
    <div className={leadingIconStyle}>
      <SVGIcon
        icon={icon}
        width={iconSize}
        height={iconSize}
        color={color}
        isActive={isActive}
      />
    </div>
  );
}

ButtonLeadingIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  iconSize: PropTypes.number,
  isActive: PropTypes.bool,
};

ButtonLeadingIcon.defaultProps = {
  icon: "",
  color: null,
  iconSize: 20,
  isActive: false,
};
