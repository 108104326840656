import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";

export default function useInteractiveStateChangeWithTimeout({
  callbackToSetState,
  enterTimeout,
  exitTimeout,
}) {
  const [activeState, setActiveState] = useState();

  // Best practice is to wrap time based functions in useRef
  // https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  const setActive = useRef(
    debounce(() => {
      return callbackToSetState(true);
    }, enterTimeout)
  );
  const setInactive = useRef(
    debounce(() => {
      return callbackToSetState(false);
    }, exitTimeout)
  );

  // handle state change
  useEffect(() => {
    if (activeState === false) {
      return setInactive.current();
    }
    return setActive.current();
  }, [activeState, setActive]);

  // clean up any `setInactive` on unmount
  useEffect(() => {
    return setInactive.current.cancel();
  }, []);
  // clean up any `setActive` on unmount
  useEffect(() => {
    return setActive.current.cancel();
  }, []);

  // function for `Interactive` component to consume
  const handleInteractiveStateChange = useCallback(
    ({ state }) => {
      if (state.hover || state.active || state.focus) {
        setInactive.current.cancel();
        return setActiveState(true);
      }
      setActive.current.cancel();
      return setActiveState(false);
    },
    [setInactive]
  );

  return handleInteractiveStateChange;
}
