// Adapted from
// https://github.com/aaronhayes/react-use-hubspot-form/blob/master/src/useScript.ts
import { useEffect, useState } from "react";

const cachedScripts = {};

// Inject <script src=""> tag into <body> and attach load/error listeners
function injectScript(src, { onLoad, onError }) {
  // Create <script> element with passed src
  const script = document.createElement("script");
  script.src = src;
  script.async = true;

  // Add onLoad/onError events
  script.addEventListener("load", onLoad);
  script.addEventListener("error", onError);

  // Add script to document body, to begin loading
  document.body.appendChild(script);

  // Return cleanup function
  return function cleanup() {
    script.removeEventListener("load", onLoad);
    script.removeEventListener("error", onError);

    if (script.parentNode) {
      script.parentNode.removeChild(script);
    }
  };
}

// Hook to inject script tag (if not already injected), and return state
// variables representing load/error state
export default function useScript(src) {
  const [loaded, setLoaded] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    // Get script from cache
    let script = cachedScripts[src];

    // When <script> is loaded, call all onLoad methods and set isLoaded flag
    // on cached script item
    function onLoad() {
      cachedScripts[src].isLoaded = true;
      cachedScripts[src].onLoad.forEach((set) => {
        set(true);
      });
    }

    // When <script> fails to load, call all onError methods
    function onError() {
      cachedScripts[src].onError.forEach((set) => {
        set(true);
      });
    }

    if (script && script.isLoaded === true) {
      // Short-circuit if in previously used + loaded
      setLoaded(true);
    } else if (script && script.isLoaded === false) {
      // Script exists, but is currently being loaded
      // Add callbacks to onLoad and onError arrays to be called on completion
      script.onLoad.push(setLoaded);
      script.onError.push(setError);
    } else {
      // Not yet loaded/used
      // Add item to cache and inject/create script tag to start loading
      // eslint-disable-next-line no-multi-assign
      script = cachedScripts[src] = {
        cleanup: injectScript(src, { onLoad, onError }),
        isLoaded: false,
        onLoad: [setLoaded],
        onError: [setError],
      };
    }

    // Return cleanup method returned by `injectScript`, to remove <script> tag
    // and unbind listeners on removal
    return script.cleanup;
  }, [src]);

  return [loaded, error];
}
