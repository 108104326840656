// extracted by mini-css-extract-plugin
export var azureStyle = "heading-module--azureStyle--JGeP7";
export var cloudBurstStyle = "heading-module--cloudBurstStyle--zKuYg";
export var grayStyle = "heading-module--grayStyle--YqEXu";
export var heading1Style = "heading-module--heading1Style--N2oe8";
export var heading2Style = "heading-module--heading2Style--wXLKL";
export var heading3Style = "heading-module--heading3Style--DP3PE";
export var heading4Style = "heading-module--heading4Style--D5T2+";
export var heading5Style = "heading-module--heading5Style--IPvDX";
export var heading6Style = "heading-module--heading6Style--oL26y";
export var heading7Style = "heading-module--heading7Style--8sH-m";
export var isBaseFontWeightStyle = "heading-module--isBaseFontWeightStyle--T1G7b";
export var isCenteredStyle = "heading-module--isCenteredStyle--yrWom";
export var isExtraBoldStyle = "heading-module--isExtraBoldStyle--nuedt";
export var isUppercase = "heading-module--isUppercase--MMTa7";
export var isUppercaseStyle = "heading-module--isUppercaseStyle--c-49r";
export var isXXLStyle = "heading-module--isXXLStyle--2jINR";
export var marginBottomLgStyle = "heading-module--marginBottomLgStyle--EeQ2O";
export var marginBottomMdStyle = "heading-module--marginBottomMdStyle--6I0O-";
export var marginBottomNoneStyle = "heading-module--marginBottomNoneStyle--khANa";
export var marginBottomSmStyle = "heading-module--marginBottomSmStyle--JqPIL";
export var marginBottomXsStyle = "heading-module--marginBottomXsStyle--+bqn9";
export var marginLeftLgStyle = "heading-module--marginLeftLgStyle--mdhxI";
export var marginLeftMdStyle = "heading-module--marginLeftMdStyle--Ww6tZ";
export var marginLeftNoneStyle = "heading-module--marginLeftNoneStyle--N0HqX";
export var marginLeftSmStyle = "heading-module--marginLeftSmStyle--tYWE4";
export var marginLeftXsStyle = "heading-module--marginLeftXsStyle--54RVZ";
export var marginRightLgStyle = "heading-module--marginRightLgStyle--VJanV";
export var marginRightMdStyle = "heading-module--marginRightMdStyle--teD2e";
export var marginRightNoneStyle = "heading-module--marginRightNoneStyle--GQCsz";
export var marginRightSmStyle = "heading-module--marginRightSmStyle--d6IE2";
export var marginRightXsStyle = "heading-module--marginRightXsStyle--wRacD";
export var marginTopLgStyle = "heading-module--marginTopLgStyle--gBBRY";
export var marginTopMdStyle = "heading-module--marginTopMdStyle--0RXo4";
export var marginTopNoneStyle = "heading-module--marginTopNoneStyle--R8jEY";
export var marginTopSmStyle = "heading-module--marginTopSmStyle--Van+h";
export var marginTopXsStyle = "heading-module--marginTopXsStyle--Ksqon";
export var noMarginStyle = "heading-module--noMarginStyle--9c+Zx";
export var noWrapStyle = "heading-module--noWrapStyle--Mzvp1";
export var whiteStyle = "heading-module--whiteStyle--KRjni";