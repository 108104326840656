//  intentionally just detect a subset of image file extensions
//  which are suitable for use on the web, otherwise we
//  can use https://www.npmjs.com/package/is-image or similar
export const IMAGE_EXTENSIONS = /\.(gif|jpe?g|svg|png|webp)($|\?)/i;

//  from https://github.com/CookPete/react-player/blob/master/src/patterns.js
//  can be probably constrained further to web-suitable only
export const AUDIO_EXTENSIONS = /\.(m4a|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i;

//  from https://github.com/CookPete/react-player/blob/master/src/patterns.js
//  can be probably constrained further to web-suitable only
export const VIDEO_EXTENSIONS = /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i;

export const isImageUrl = (url = "") => {
  return IMAGE_EXTENSIONS.test(url);
};

export const isAudioUrl = (url = "") => {
  return AUDIO_EXTENSIONS.test(url);
};

export const isVideoUrl = (url = "") => {
  return VIDEO_EXTENSIONS.test(url);
};

export const isSvgUrl = (url = "") => {
  return /\.(svg)($|\?)/i.test(url);
};

export const isGifUrl = (url = "") => {
  return /\.(gif)($|\?)/i.test(url);
};
