// extracted by mini-css-extract-plugin
export var azureStyle = "svg_icon-module--azureStyle--DqUj5";
export var chevronDownTransitionStyle = "svg_icon-module--chevronDownTransitionStyle--jdCom";
export var chevronExternalLinkTransitionStyle = "svg_icon-module--chevronExternalLinkTransitionStyle--+MhWq";
export var chevronLeftTransitionStyle = "svg_icon-module--chevronLeftTransitionStyle---kJ9v";
export var chevronRightTransitionStyle = "svg_icon-module--chevronRightTransitionStyle--hspav";
export var cloudBurst10Style = "svg_icon-module--cloudBurst10Style--tuefP";
export var cloudBurst30Style = "svg_icon-module--cloudBurst30Style--kH-Ig";
export var cloudBurst50Style = "svg_icon-module--cloudBurst50Style--2DIWh";
export var cloudBurst80Style = "svg_icon-module--cloudBurst80Style--ediKI";
export var cloudBurstStyle = "svg_icon-module--cloudBurstStyle--AogZ3";
export var electricVioletStyle = "svg_icon-module--electricVioletStyle--JmJlD";
export var isActiveStyle = "svg_icon-module--isActiveStyle--dWN7B";
export var lightGray130Style = "svg_icon-module--lightGray130Style--G0dkA";
export var sunStyle = "svg_icon-module--sunStyle--JWolc";
export var whiteStyle = "svg_icon-module--whiteStyle--DR+kE";
export var wrapperStyle = "svg_icon-module--wrapperStyle--N1LSF";