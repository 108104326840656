/* eslint-disable no-console */
import { StoryblokError } from "../errors/errors";

// Blacklisted keys that aren't used when iterating over Storyblok content
export const PROPRIETARY_KEYS = ["_uid", "_editable", "component", "plugin"];

export function getStoryblokNonProprietaryKeys(object) {
  return Object.keys(object).filter((key) => {
    return !PROPRIETARY_KEYS.includes(key);
  });
}

// TODO: not sure if needed?
export function getStoryblokNonProprietaryValues(object) {
  return getStoryblokNonProprietaryKeys(object).reduce((carry, key) => {
    return { ...carry, [key]: object[key] };
  }, {});
}

export function parseStoryblokEntry(key, entry) {
  if (!entry) {
    console.warn("Missing data for", key);
    throw new StoryblokError(`Missing data for ${key}`);
  }

  //  component might be coming from the storyblok API in editor
  //  directly in which case it will be already parsed
  if (typeof entry.content === "object") {
    return entry.content;
  }

  try {
    return {
      ...JSON.parse(entry.content),
      ...entry.fields,
    };
  } catch (e) {
    console.warn("Error parsing data for", key);
    throw new StoryblokError(`Error parsing data for ${key}`);
  }
}

// Return combined global and page metadata
export function getPageMetadata(pageMetadata, globalMetadata) {
  const metadata = {};

  // Iterate over all non-Storyblok keys within global metadata object
  getStoryblokNonProprietaryKeys(globalMetadata).forEach((key) => {
    if (key === "image") {
      metadata[key] = pageMetadata?.[key]?.id
        ? pageMetadata[key]
        : globalMetadata[key];
    } else {
      metadata[key] = pageMetadata[key] || globalMetadata[key];
    }
  });

  return metadata;
}

// ToDo: [MW-410] write tests around ineditor functionality

export function inEditor() {
  if (typeof window !== "undefined") {
    // Storyblok API seems to return true for "inEditor" when running cypress
    if (window.Cypress) {
      return false;
    }
    if (window.storyblok?.inEditor) {
      return true;
    }
  }
  return false;
}

// User is on /editor page, but may not be inside storyblok editor itself
// (e.g previewing page)
export function isEditorRoute() {
  return (
    typeof window !== "undefined" &&
    window.location.pathname.startsWith("/editor")
  );
}
