import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import RemoteImage from "~components/utils/remote_image/remote_image";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY, MEDIA } from "../../../prop_types";
import { imageStyle, isWidthConstrainedStyle } from "./image.module.scss";

export default function Image({
  height,
  image,
  isAboveTheFold,
  isCentered,
  isWidthConstrained,
  objectFit,
  opacity,
  settings,
  testId,
  width,
  ...rest
}) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  const imageClassNames = classNames(imageStyle, ...settingsClassNames, {
    [isWidthConstrainedStyle]: isWidthConstrained,
  });

  const style = {};

  if (width) {
    style.width = `${width}px`;
  }

  if (isCentered) {
    style.margin = "0 auto";
  }

  if (height) {
    style.height = `${height}px`;
  }

  if (opacity) {
    style.opacity = `${opacity}`;
  }

  return (
    <SbEditable content={rest}>
      <div className={imageClassNames} style={style} data-testid={testId}>
        <RemoteImage
          {...image}
          width={width}
          objectFit={objectFit}
          isAboveTheFold={isAboveTheFold}
        />
      </div>
    </SbEditable>
  );
}

Image.defaultProps = {
  height: null,
  image: null,
  isAboveTheFold: false,
  isCentered: false,
  isWidthConstrained: true,
  objectFit: "contain",
  opacity: 1,
  settings: null,
  testId: null,
  width: null,
};

Image.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: MEDIA,
  isAboveTheFold: PropTypes.bool,
  isCentered: PropTypes.bool,
  isWidthConstrained: PropTypes.bool,
  objectFit: PropTypes.oneOf(["contain", "cover"]),
  opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  settings: BLOK_ARRAY,
  testId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
