export default function getItemsWithDropdown({ items }) {
  if (items.length > 0) {
    return items.map((item) => {
      if (item.component === "navGroup") {
        return true;
      }
      return false;
    });
  }
  return [];
}
