import React, { useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import { LINK } from "../../../../../../../prop_types";
import * as classes from "./nav_group_item.module.scss";

function IconCaret() {
  return (
    <svg className={classes.caret}>
      <use xlinkHref="/images/icon_caret_down.svg#icon" />
    </svg>
  );
}

export default function NavGroupItem({
  index,
  title,
  link,
  isActive,
  debouncedSetActiveItem,
  setActiveItem,
}) {
  const buttonClassNames = classNames(classes.button, {
    [classes.isActive]: isActive,
  });

  const debouncedHandleActivate = useCallback(() => {
    return debouncedSetActiveItem(index);
  }, [debouncedSetActiveItem, index]);

  const handleActivate = useCallback(() => {
    return setActiveItem(index);
  }, [setActiveItem, index]);

  if (link) {
    return (
      <div
        onMouseEnter={debouncedHandleActivate}
        onFocus={debouncedHandleActivate}
      >
        <LinkWrapper link={link} block>
          <div className={classes.navGroupItem}>{title || "Title"}</div>
        </LinkWrapper>
      </div>
    );
  }

  return (
    <button
      className={buttonClassNames}
      type="button"
      onMouseEnter={debouncedHandleActivate}
      onFocus={debouncedHandleActivate}
      onClick={handleActivate}
    >
      {title || "Group"}
      <IconCaret />
    </button>
  );
}

NavGroupItem.defaultProps = {
  index: null,
  isActive: false,
  link: null,
  debouncedSetActiveItem: () => {},
  setActiveItem: () => {},
  title: "",
};

NavGroupItem.propTypes = {
  index: PropTypes.number,
  isActive: PropTypes.bool,
  link: LINK,
  debouncedSetActiveItem: PropTypes.func,
  setActiveItem: PropTypes.func,
  title: PropTypes.string,
};
