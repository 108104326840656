import { useEffect, useState } from "react";

export default function useComponentMargin({
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
}) {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (!Number.isNaN(marginTop)) {
      setStyle((currentStyle) => {
        return {
          ...currentStyle,
          marginTop: `${marginTop}px`,
        };
      });
    }
    if (!Number.isNaN(marginBottom)) {
      setStyle((currentStyle) => {
        return {
          ...currentStyle,
          marginBottom: `${marginBottom}px`,
        };
      });
    }
    if (!Number.isNaN(marginLeft)) {
      setStyle((currentStyle) => {
        return {
          ...currentStyle,
          marginLeft: `${marginLeft}px`,
        };
      });
    }
    if (!Number.isNaN(marginRight)) {
      setStyle((currentStyle) => {
        return {
          ...currentStyle,
          marginRight: `${marginRight}px`,
        };
      });
    }
  }, [marginBottom, marginLeft, marginRight, marginTop]);

  return style;
}
