// Adapted from
// https://github.com/aaronhayes/react-use-hubspot-form/blob/master/src/index.ts
import { useEffect, useState } from "react";
import useScript from "../use_script/use_script";

const PREFIX = "__form";
let id = 0;

// Generates unique className on each call
function getClassName() {
  id += 1;
  return [PREFIX, id].join("-");
}

export default function useHubSpotForm({
  portalId,
  formId,
  lang,
  options = {},
}) {
  // Generate unique classname to be added to form destination/container
  const [className, setClassName] = useState();

  // Load script tag (if not already injected/loaded)
  const [isLoaded] = useScript("//js.hsforms.net/forms/v2.js");

  // Set className initially
  useEffect(() => {
    setClassName(getClassName());
  }, []);

  useEffect(() => {
    const target = `.${className}`;

    if (isLoaded && window.hbspt) {
      window.hbspt.forms.create({
        portalId,
        formId,
        target,

        //  make sure we can embded the same form
        //  multiple times without the submit being triggered
        //  for all of them
        formInstanceId: className,

        //  do not inject hubspot CSS
        cssRequired: "",

        // disable <iframe> embed
        css: "",

        // locale: lang, // <- debug missing translations that appear broken in a Nov 22 update by hubspot

        // Additional hubspot form options
        // https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
        ...options,
      });
    }

    // Remove form (if exists) when hook re-runs
    return () => {
      const form = document.querySelector(`${target} form`);
      if (form) {
        form.parentNode.removeChild(form);
      }
    };
  }, [className, isLoaded, portalId, formId, options, lang]);

  return [className, isLoaded];
}
