import React from "react";
import Image from "~components/atoms/image/image";
import { getLinkProps } from "~utils/link_utils/getLinkProps/getLinkProps";
import { LINK, MEDIA } from "../../../prop_types";
import * as classes from "./social_button.module.scss";

export default function SocialButton({ icon, link }) {
  const classNames = [classes.socialButton];

  return (
    <a
      className={classNames.join(" ")}
      data-testid="social-button"
      {...getLinkProps(link)}
    >
      <Image image={icon} />
    </a>
  );
}

SocialButton.propTypes = {
  link: LINK.isRequired,
  icon: MEDIA.isRequired,
};
