import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import LoadingIndicator from "~components/atoms/loading_indicator/loading_indicator";
import gtagEventContactFormSubmission from "~utils/gtag_events/gtag_event_contact_form_submission/gtag_event_contact_form_submission";
import gtagEventRoastAgencyConversion from "~utils/gtag_events/gtag_event_roast_agency_conversion/gtag_event_roast_agency_conversion";
import useHubspotForm from "~hooks/use_hubspot_form/use_hubspot_form";
import useSettingsClassNames from "~hooks/use_settings_classnames/use_settings_classnames";
import { BLOK_ARRAY, COLOR } from "../../../prop_types";
import * as classes from "./form.module.scss";

export default function Form({
  formLabel,
  formConversionType,
  portalId,
  formId,
  layout,
  lang,
  buttonColor,
  successColor,
  errorColor,
  settings,
  isCentered,
  backgroundColor,
  onClick,
  id,
  ...rest
}) {
  const [settingsClassNames] = useSettingsClassNames(settings);

  /* —————————————————————————————————————————————————————————————————————————————
  |                              SET FORM OPTIONS                               |
  ——————————————————————————————————————————————————————————————————————————————— */

  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions({
      submitButtonClass: classes[`button_${buttonColor}`],
    });
  }, [buttonColor]);

  /* —————————————————————————————————————————————————————————————————————————————
  |                                USE FORM HOOK                                |
  ——————————————————————————————————————————————————————————————————————————————— */
  const [hubspotClassName, isLoaded] = useHubspotForm({
    portalId,
    formId,
    lang,
    options,
  });

  /* —————————————————————————————————————————————————————————————————————————————
  |                           HANDLE FORM SUBMISSION                            |
  ——————————————————————————————————————————————————————————————————————————————— */

  // Cache hsFormCallback response as it seems to be being returned twice per id,
  // regardless of eventListener cleanup in useEffect hook
  const [hsCallbackEventData, setHsCallbackEventData] = useState(null);

  const handleFormSubmission = useCallback(
    (event) => {
      // Only update state on new events from hsFormCallback
      if (event.data.id !== hsCallbackEventData?.id) {
        setHsCallbackEventData(event.data);
      }
    },
    [hsCallbackEventData?.id]
  );

  useEffect(() => {
    window.addEventListener("message", handleFormSubmission);
    return () => {
      window.removeEventListener("message", handleFormSubmission);
    };
  });

  // Watch hsCallbackEventData state variable and fire analytics events on change
  useEffect(() => {
    if (
      hsCallbackEventData?.type === "hsFormCallback" &&
      hsCallbackEventData?.eventName === "onFormSubmitted"
    ) {
      gtagEventContactFormSubmission({
        formConversionType,
        formLabel,
        formId,
      });

      gtagEventRoastAgencyConversion();

      window.dataLayer.push({
        event: formConversionType,
        form_label: formLabel,
      });

      if (onClick) {
        onClick();
      }
    }
  }, [formConversionType, formId, formLabel, hsCallbackEventData, onClick]);

  /* —————————————————————————————————————————————————————————————————————————————
  |                                FORM STYLING                                 |
  ——————————————————————————————————————————————————————————————————————————————— */

  const classNames = [
    classes.form,
    classes[`layout_${layout}`],
    classes[`backgroundColor_${backgroundColor}`],
    errorColor && classes[`errorColor_${errorColor}`],
    isCentered && classes.isCentered,
    hubspotClassName,
    ...settingsClassNames,
  ];

  const style = {};

  if (successColor && successColor.color) {
    style.color = successColor.color;
  }

  return (
    <div data-testid="form">
      {isLoaded ? (
        <SbEditable content={rest}>
          <div id={id} className={classNames.join(" ")} style={style} />
        </SbEditable>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
}

Form.defaultProps = {
  formLabel: "main_contact_form",
  formConversionType: "primary_conversion",
  portalId: "3954168",
  id: "",
  formId: "",
  successColor: null,
  errorColor: "red",
  settings: null,
  isCentered: false,
  backgroundColor: "bright",
  lang: "en-gb",
  onClick: null,
};

Form.propTypes = {
  formLabel: PropTypes.string,
  formConversionType: PropTypes.oneOf([
    "primary_conversion",
    "secondary_conversion",
    "non_conversion",
  ]),
  portalId: PropTypes.string,
  id: PropTypes.string,
  formId: PropTypes.string,
  layout: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  successColor: COLOR,
  errorColor: PropTypes.oneOf(["red", "pink"]),
  settings: BLOK_ARRAY,
  isCentered: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(["bright", "dark"]),
  lang: PropTypes.oneOf([
    "",
    "en-gb",
    "de-de",
    "it-it",
    "es-es",
    "fr-fr",
    "en-ie",
    "en-au",
  ]),
  onClick: PropTypes.func,
};
