function removeDefaultLanguagePrefix(url) {
  let localUrl = url;

  if (localUrl?.startsWith("en-gb/")) {
    localUrl = localUrl.replace("en-gb/", "");
  }
  if (localUrl?.endsWith("/en-gb")) {
    localUrl = localUrl.replace("/en-gb", "");
  }
  if (localUrl?.includes("/en-gb/")) {
    localUrl = localUrl.replace("/en-gb/", "/");
  }

  return localUrl;
}

module.exports = {
  removeDefaultLanguagePrefix,
};
