// ToDo: [MW-232] Abstract this check for console
export default function trackOutboundLinkToConsole(linkUrl) {
  const isConsoleLink = linkUrl.startsWith("https://console.truelayer.com/");

  if (isConsoleLink && typeof window !== "undefined" && window.gtag) {
    window.gtag("event", "sign_up", {
      conversion_type: "primary_conversion",
    });

    // Send event to Roast's google campaign manager
    // Roast are our paid media agency
    window.gtag("event", "conversion", {
      allow_custom_scripts: true,
      send_to: "DC-11060861/conve0/truel00+unique",
    });

    // LinkedIn insights event
    window.lintrk("track", { conversion_id: 5220761 });
  }
}
