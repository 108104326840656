import PropTypes from "prop-types";
import { inEditor } from "~utils/storyblok/storyblok";

function ThirdParty({ zendeskChatLocaleOverride }) {
  //  avoid adding third party scripts when in editor (intercom, GA etc)
  if (inEditor()) {
    return null;
  }
  if (typeof window !== "undefined" && window.zE) {
    try {
      window.zE("messenger:set", "locale", zendeskChatLocaleOverride);
    } catch {
      // eslint-disable-next-line no-console
      console.warn("[zendesk-chat] Unable to set locale");
    }
  }

  return null;
}

ThirdParty.defaultProps = {
  zendeskChatLocaleOverride: "en-gb",
};

ThirdParty.propTypes = {
  zendeskChatLocaleOverride: PropTypes.string,
};

export default ThirdParty;
