import { useCallback, useState } from "react";

export default function useHandleInteractiveStateChange() {
  const [isActive, setIsActive] = useState(false);
  const handleInteractiveStateChange = useCallback(({ state }) => {
    if (state.hover || state.active || state.focus) {
      return setIsActive(true);
    }
    return setIsActive(false);
  }, []);

  return [isActive, handleInteractiveStateChange];
}
