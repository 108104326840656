import React from "react";
import PropTypes from "prop-types";
import MobileNavGroup from "~components/organisms/header/header_components/mobile_nav/mobile_nav_components/mobile_nav_group/mobile_nav_group";
import MobileNavGroupItem from "~components/organisms/header/header_components/mobile_nav/mobile_nav_components/mobile_nav_group_item/mobile_nav_group_item";
import { BLOK_ARRAY } from "../../../../../../../prop_types";
import {
  columnLeftStyle,
  navListItemStyle,
} from "./mobile_nav_left_menu.module.scss";

export default function MobileNavLeftMenu({
  items,
  setActiveGroupIndex,
  activeGroupIndex,
  toggleMobileMenu,
}) {
  return (
    <menu className={columnLeftStyle}>
      {items &&
        items.map((item, index) => {
          if (item.component === "navGroupItem") {
            return (
              <MobileNavGroupItem
                {...item}
                key={item._uid}
                onClick={toggleMobileMenu}
              />
            );
          }
          return (
            <button
              className={navListItemStyle}
              type="button"
              onClick={() => {
                setActiveGroupIndex(index);
              }}
              key={item._uid}
            >
              <MobileNavGroup {...item} isActive={activeGroupIndex === index} />
            </button>
          );
        })}
    </menu>
  );
}

MobileNavLeftMenu.propTypes = {
  activeGroupIndex: PropTypes.number,
  isMenuOpen: PropTypes.bool,
  items: BLOK_ARRAY,
  setActiveGroupIndex: PropTypes.func,
  toggleMobileMenu: PropTypes.func,
};

MobileNavLeftMenu.defaultProps = {
  activeGroupIndex: null,
  isMenuOpen: false,
  items: null,
  setActiveGroupIndex: false,
  toggleMobileMenu: null,
};
