/* eslint-disable camelcase */
import React from "react";
import GridNew from "~components/atoms/grid_new/grid_new";
import {
  BLOK_ARRAY,
  RESPONSIVE_VALUE_NEW,
  STORYBLOK_ENTRY_ARRAY,
} from "../../../../../../../prop_types";
import DesktopNavCategory from "../desktop_nav_category/desktop_nav_category";
import NavCTA from "../desktop_nav_cta/nav_cta";
import { wrapperStyle } from "./desktop_nav_dropdown_menu.module.scss";

export default function DesktopNavDropdownMenu({
  categories,
  cta,
  gridSplitNew,
}) {
  const hasCategories = categories?.length > 0;

  return (
    <div className={wrapperStyle}>
      <GridNew split={gridSplitNew} hasColumnGap={false}>
        {hasCategories &&
          categories.map((item) => {
            return <DesktopNavCategory {...item} key={item._uid} />;
          })}
      </GridNew>
      {cta?.length > 0 && <NavCTA {...cta[0]} />}
    </div>
  );
}

DesktopNavDropdownMenu.defaultProps = {
  categories: null,
  cta: null,
  gridSplitNew: null,
};

DesktopNavDropdownMenu.propTypes = {
  categories: BLOK_ARRAY,
  cta: STORYBLOK_ENTRY_ARRAY,
  gridSplitNew: RESPONSIVE_VALUE_NEW,
};
