import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import useSiteUrl from "~hooks/use_site_url/use_site_url";
import AssetsContext from "../../../assets_context";
import {
  createAlternateLink,
  getCanonicalSlug,
  getEnglishVersionSlug,
  getSocialShareImageSlug,
} from "./helper_functions";
import getMetaTitle from "./helper_functions/get_meta_title/get_meta_title";

export default function Metadata({
  title,
  description,
  author,
  language,
  image,
  alternates,
  fullSlug,
  programmaticPagePath,
  programmaticPageTitle,
  isListPage,
}) {
  const origin = useSiteUrl();
  const { assets } = useContext(AssetsContext);

  const socialShareImageSlug = useMemo(() => {
    return getSocialShareImageSlug({
      assets,
      image,
      origin,
    });
  }, [assets, image, origin]);

  const canonicalSlug = useMemo(() => {
    return getCanonicalSlug({
      origin,
      fullSlug,
      programmaticPagePath,
    });
  }, [fullSlug, origin, programmaticPagePath]);

  const englishVersionSlug = useMemo(() => {
    return getEnglishVersionSlug({
      alternates,
      fullSlug,
      language,
      origin,
    });
  }, [alternates, fullSlug, language, origin]);

  const metaTitle = useMemo(() => {
    return getMetaTitle({ programmaticPageTitle, title });
  }, [programmaticPageTitle, title]);

  return (
    <Helmet defer={false} title={metaTitle}>
      <html lang={language} />
      <meta name="theme-color" content="#fff" />
      <meta name="description" content={description} />

      <link rel="canonical" href={canonicalSlug} />
      {/* Add an x-default hreflang tag if alternates defined in storyblok */}
      {alternates?.length > 0 && englishVersionSlug && !isListPage && (
        <link rel="alternate" href={englishVersionSlug} hrefLang="x-default" />
      )}

      {/* add self referencing href lang if alternates defined in storyblok */}
      {alternates?.length > 0 && !isListPage && (
        <link
          rel="alternate"
          href={canonicalSlug}
          hrefLang={fullSlug.split("/")[0]}
        />
      )}

      {/* add hreflang alternate tags to other pages if alternates defined in storyblok */}
      {alternates?.length > 0 &&
        alternates.map((alternate) => {
          return createAlternateLink({ alternate, origin, isListPage });
        })}

      <meta
        name="ahrefs-site-verification"
        content="bcb32992a4a49651059dd10ed8108acbfeb551ef607c4c5928b94e10bf709ea8"
      />

      {/*  verification for Alex */}
      <meta
        name="google-site-verification"
        content="sfc6MxT2PK6jfrLkaNCMK4iwWTrjSlCrxCbNQR1vYZo"
      />
      {/* facebook tracking pixel domain verification */}
      <meta
        name="facebook-domain-verification"
        content="40hh9o9svwgfafsb29gx4b0i3vnz2s"
      />
      {/* Open graph tags */}
      <meta property="og:url" content={canonicalSlug} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:owners" content={author} />

      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:description" content={description} />

      {/* Social share images */}
      {socialShareImageSlug && (
        <meta name="twitter:image" content={socialShareImageSlug} />
      )}
      {socialShareImageSlug && (
        <meta property="og:image" content={socialShareImageSlug} />
      )}
    </Helmet>
  );
}

Metadata.defaultProps = {
  alternates: null,
  author: undefined,
  description: undefined,
  fullSlug: null,
  image: null,
  title: undefined,
  programmaticPageTitle: null,
  programmaticPagePath: null,
  isListPage: false,
};

Metadata.propTypes = {
  alternates: PropTypes.arrayOf(PropTypes.shape({})),
  author: PropTypes.string,
  description: PropTypes.string,
  fullSlug: PropTypes.string,
  image: PropTypes.shape({
    filename: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  language: PropTypes.string.isRequired,
  title: PropTypes.string,
  programmaticPageTitle: PropTypes.string,
  programmaticPagePath: PropTypes.string,
  isListPage: PropTypes.bool,
};
