// TODO: add to documentation how this works
// Uses ISO 3166-1 alpha-2 code(s)

const {
  ensureTrailingSlash,
} = require("./src/utils/url_utils/ensureTrailingSlash/ensureTrailingSlash");
const {
  LANGUAGE_REGEX,
} = require("./src/utils/regex_strings/regex_language_country_code.js/regex_language_country_code");
const {
  removeDefaultLanguagePrefix,
} = require("./src/utils/url_utils/remove_default_language_prefix/remove_default_language_prefix");

function formatPageSlug(slug) {
  let formattedSlug = slug;
  formattedSlug = removeDefaultLanguagePrefix(formattedSlug);
  formattedSlug = ensureTrailingSlash(formattedSlug);

  return formattedSlug;
}

function getLanguageForEntry(entry) {
  const matches = entry.full_slug.match(LANGUAGE_REGEX);
  return (matches && matches[1]) || null;
}

function getContentForEntry(entry) {
  let content = {};
  try {
    content = JSON.parse(entry.content);
  } catch (e) {
    console.warn("Failed parsing content for", entry.full_slug);
  }
  return content;
}

function getPagePathForEntry(entry) {
  return formatPageSlug(entry.full_slug);
}

function getAssetsForEntry(entry) {
  return entry.assets.reduce((carry, item) => {
    carry.push(item.id);
    return carry;
  }, []);
}

function getContextForEntry(entry) {
  const lang = getLanguageForEntry(entry);

  return {
    id: entry.id,
    lang,

    // need to know the language to get language-specific global components
    // (e.g. metadata, menu), however, not possible to use graphql variable with
    // regex, so constructing regex here and using it as string in graphql page query
    // @see https://github.com/gatsbyjs/gatsby/issues/10009
    langRegex: `/^${lang}//`,
  };
}

module.exports = {
  formatPageSlug,
  getAssetsForEntry,
  getLanguageForEntry,
  getContextForEntry,
  getContentForEntry,
  getPagePathForEntry,
};
