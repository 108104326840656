import React from "react";
import PropTypes from "prop-types";
import TransitionComponent from "~components/utils/transition/transition";
import { BLOK_ARRAY } from "../../../../../../../prop_types";
import MobileNavCategory from "../mobile_nav_category/mobile_nav_category";
import {
  columnRightListStyle,
  columnRightStyle,
} from "./mobile_nav_right_menu.module.scss";

export default function MobileNavRightMenu({
  items,
  callbackOnClickItem,
  activeGroupIndex,
}) {
  const shouldShowMenu = items[activeGroupIndex]?.categories?.length > 0;

  return (
    <div className={columnRightStyle}>
      <menu className={columnRightListStyle}>
        {items[activeGroupIndex]?.categories?.length > 0 &&
          items[activeGroupIndex].categories.map((item) => {
            return (
              <TransitionComponent
                appear
                transition="slideRight"
                isActive={shouldShowMenu}
                unmountOnExit
                key={item._uid}
              >
                <MobileNavCategory
                  {...item}
                  callbackOnClickItem={callbackOnClickItem}
                />
              </TransitionComponent>
            );
          })}
      </menu>
    </div>
  );
}

MobileNavRightMenu.propTypes = {
  items: BLOK_ARRAY,
  callbackOnClickItem: PropTypes.func,
  activeGroupIndex: PropTypes.number,
};

MobileNavRightMenu.defaultProps = {
  items: null,
  callbackOnClickItem: () => {},
  activeGroupIndex: null,
};
