import PropTypes from "prop-types";

export const STORYBLOK_ENTRY = PropTypes.shape({
  content: PropTypes.oneOfType([
    // Could be an object, if using API loaded data in editor
    PropTypes.shape({}),
    // Returned as string at build time from storyblok GraphQL api
    PropTypes.string,
  ]),
});

export const STORYBLOK_ENTRY_ARRAY = PropTypes.arrayOf(STORYBLOK_ENTRY);

export const GLOBAL_PAGE_QUERY = PropTypes.shape({
  globalMetadata: STORYBLOK_ENTRY,
  header: STORYBLOK_ENTRY,
  footer: STORYBLOK_ENTRY,
  thirdParty: STORYBLOK_ENTRY,
});

export const BLOK = PropTypes.shape({
  key: PropTypes.string,
  blok: PropTypes.shape({}),
});

export const BLOK_ARRAY = PropTypes.arrayOf(BLOK);

export const RICH_TEXT = PropTypes.shape({
  type: PropTypes.string,
  content: PropTypes.array, // eslint-disable-line
});

export const LINK = PropTypes.shape({
  url: PropTypes.string,
  cached_url: PropTypes.string,
  story: PropTypes.shape({
    url: PropTypes.string,
  }),
  linktype: PropTypes.oneOf(["story", "url", "asset", "email"]),
});

export const MEDIA = PropTypes.shape({
  id: PropTypes.number,
  filename: PropTypes.string,
  description: PropTypes.string,
});

export const ASSET = PropTypes.shape({
  id: PropTypes.string.isRequired,
  publicURL: PropTypes.string.isRequired,
  childImageSharp: PropTypes.shape({}),
});

export const AUTHOR = PropTypes.shape({
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
    bio: PropTypes.string.isRequired,
    linkedLink: LINK,
    twitterLink: LINK,
  }),
});

export const COLOR = PropTypes.shape({
  color: PropTypes.string,
});

export const GRADIENT = PropTypes.shape({
  gradient: PropTypes.shape({
    style: PropTypes.string,
  }),
});

export const RICH_TEXT_CONTENT = PropTypes.shape({
  type: PropTypes.string,
  marks: PropTypes.array, // eslint-disable-line
});

export const RESPONSIVE_VALUE = PropTypes.shape({
  desktop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tablet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobile: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const RESPONSIVE_VALUE_NEW = PropTypes.shape({
  XL: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  L: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  M: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  S: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  XS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  XXS: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const RESOURCE_HUB_SECTION = PropTypes.shape({
  title: PropTypes.string,
  link: LINK,
  items: PropTypes.arrayOf(PropTypes.shape({})),
});
