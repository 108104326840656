// extracted by mini-css-extract-plugin
export var backgroundColor_bright = "form-module--backgroundColor_bright--wYs1k";
export var backgroundColor_dark = "form-module--backgroundColor_dark--Sw8+g";
export var button_yellow = "form-module--button_yellow--YYyJT";
export var button_yellowTransparent = "form-module--button_yellowTransparent--F-1aP";
export var disabledStyle = "form-module--disabledStyle--JOcK2";
export var errorColor_pink = "form-module--errorColor_pink--5nrxT";
export var errorColor_red = "form-module--errorColor_red--CjnaO";
export var form = "form-module--form--cBBEi";
export var isActiveStyle = "form-module--isActiveStyle--zrjj0";
export var isCentered = "form-module--isCentered--88kiQ";
export var layout_multiLine = "form-module--layout_multiLine--zLwcz";
export var layout_singleLine = "form-module--layout_singleLine--P6Mxy";