import PropTypes from "prop-types";
import React from "react";

import * as classes from "./loading_indicator.module.scss";

export default function LoadingIndicator({ size, color }) {
  const loadingIndicatorClassNames = [
    classes.loadingIndicator,
    classes[`size_${size}`],
    classes[`color_${color}`],
  ];
  return (
    <div className={loadingIndicatorClassNames.join(" ")}>
      <div className={classes.cube1} />
      <div className={classes.cube2} />
    </div>
  );
}

LoadingIndicator.propTypes = {
  size: PropTypes.oneOf(["small", "large"]),
  color: PropTypes.oneOf(["white", "gray"]),
};

LoadingIndicator.defaultProps = {
  size: "large",
  color: "gray",
};
