import React from "react";
import PropTypes from "prop-types";
import GridItemNew from "~components/atoms/grid_new/grid_item_new/grid_item_new";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import NavLink from "~components/molecules/nav_link/nav_link";
import { RESPONSIVE_VALUE_NEW } from "../../../../../../../prop_types";
import { titleStyle } from "./desktop_nav_category.module.scss";

export default function DesktopNavCategory({
  title,
  children,
  gridSpanNew,
  gridSplitNew,
}) {
  return (
    <GridItemNew gridSpan={gridSpanNew} hasBorder>
      {title && (
        <div className={titleStyle}>
          <Heading visualLevel={7} isUppercase>
            {title}
          </Heading>
        </div>
      )}
      <GridNew split={gridSplitNew} hasRowGap={false}>
        {children?.length > 0 &&
          children.map((child) => {
            return <NavLink {...child} key={child._uid} />;
          })}
      </GridNew>
    </GridItemNew>
  );
}

DesktopNavCategory.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
  gridSpanNew: RESPONSIVE_VALUE_NEW,
  gridSplitNew: RESPONSIVE_VALUE_NEW,
  title: PropTypes.string,
};

DesktopNavCategory.defaultProps = {
  children: null,
  gridSpanNew: null,
  gridSplitNew: { XXL: "1" },
  title: null,
};
