import React from "react";
import Contain from "~components/atoms/contain/contain";
import ButtonTertiary from "~components/molecules/button/button_tertiary/button_tertiary";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY, RICH_TEXT } from "../../../../../prop_types";
import {
  bannerInnerStyle,
  bannerLeftMenuStyle,
  bannerRightMenuStyle,
  bannerWrapperStyle,
} from "./banner.module.scss";

export default function Banner({ text, links }) {
  return (
    <div data-testid="banner-wrapper" className={bannerWrapperStyle}>
      <Contain>
        <div className={bannerInnerStyle}>
          {/* ———— BANNER LEFT ———— */}
          <div className={bannerLeftMenuStyle}>
            <RichText text={text} size="small" />
          </div>

          {/* ———— BANNER RIGHT ———— */}
          <menu className={bannerRightMenuStyle}>
            {links &&
              links.length > 0 &&
              links.map((linkItem) => {
                return (
                  <ButtonTertiary
                    isFontRegular
                    title={linkItem.text}
                    link={linkItem.link}
                    key={linkItem.text}
                  />
                );
              })}
          </menu>
        </div>
      </Contain>
    </div>
  );
}

Banner.defaultProps = {
  text: null,
  links: null,
};

Banner.propTypes = {
  text: RICH_TEXT,
  links: BLOK_ARRAY,
};
