import debounce from "lodash.debounce";

export default function useDebouncedHoverInteractions(
  { hoverCallback, leaveCallback, enterDelay, leaveDelay } = {
    hoverCallback: null,
    leaveCallback: null,
    enterDelay: 250,
    leaveDelay: 250,
  }
) {
  const debouncedHandleHover = debounce(() => {
    hoverCallback();
  }, enterDelay);

  const abandonHover = () => {
    debouncedHandleHover.cancel();
  };

  const debouncedHandleLeave = debounce(() => {
    leaveCallback();
  }, leaveDelay);

  const abandonLeave = () => {
    debouncedHandleLeave.cancel();
  };

  const handleHover = () => {
    abandonLeave();
    debouncedHandleHover();
  };
  const handleLeave = () => {
    abandonHover();
    debouncedHandleLeave();
  };
  return {
    handleHover,
    handleLeave,
  };
}
