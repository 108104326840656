// extracted by mini-css-extract-plugin
export var azureStyle = "tag-module--azureStyle--ERbqz";
export var disabledStyle = "tag-module--disabledStyle--yQrr2";
export var grayStyle = "tag-module--grayStyle--b9nZt";
export var isActiveTab = "tag-module--isActiveTab--WS678";
export var isActiveTabStyle = "tag-module--isActiveTabStyle--BL+ut";
export var isCategoryStyle = "tag-module--isCategoryStyle--+di3c";
export var isInteractiveStyle = "tag-module--isInteractiveStyle--QRc2x";
export var mdStyle = "tag-module--mdStyle--KTTZS";
export var smStyle = "tag-module--smStyle--lH0Eb";
export var tagStyle = "tag-module--tagStyle--0V6Ur";
export var whiteTransparentStyle = "tag-module--whiteTransparentStyle--eUBfp";
export var xsStyle = "tag-module--xsStyle--3oAfL";