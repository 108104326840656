import React from "react";
import PropTypes from "prop-types";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import { LINK } from "../../../../../../../prop_types";
import * as classes from "./mobile_nav_group_item.module.scss";

function MobileNavGroupItem({ title, link, onClick }) {
  return (
    <LinkWrapper link={link} block onClick={onClick}>
      <div className={classes.navGroupItem}>{title || "Title"}</div>
    </LinkWrapper>
  );
}

MobileNavGroupItem.defaultProps = {
  title: "",
  link: null,
  onClick: null,
};

MobileNavGroupItem.propTypes = {
  title: PropTypes.string,
  link: LINK,
  onClick: PropTypes.func,
};

export default MobileNavGroupItem;
