// extracted by mini-css-extract-plugin
export var blokMarginStyle = "rich_text-module--blokMarginStyle--5u64q";
export var colorWhiteStyle = "rich_text-module--colorWhiteStyle--mGvEj";
export var inlineCodeStyle = "rich_text-module--inlineCodeStyle--PtWZy";
export var isCenteredStyle = "rich_text-module--isCenteredStyle--orPWJ";
export var link = "rich_text-module--link--68wq+";
export var linkStyle = "rich_text-module--linkStyle--IZDnc";
export var paragraphStyle = "rich_text-module--paragraphStyle--xD59Q";
export var richTextStyle = "rich_text-module--richTextStyle--qJzWM";
export var sizeExtraSmallStyle = "rich_text-module--sizeExtraSmallStyle--zp4kv";
export var sizeLargeStyle = "rich_text-module--sizeLargeStyle--5bBXE";
export var sizeSmallStyle = "rich_text-module--sizeSmallStyle--RtdiY";
export var standFirstStyle = "rich_text-module--standFirstStyle--EbdCj";