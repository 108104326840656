export default function getComponent({
  parentComponentName,
  componentName,
  componentsMap,
}) {
  if (!componentsMap[componentName]) {
    // eslint-disable-next-line no-console
    console.warn(
      `${componentName} component is missing from ${parentComponentName}`
    );
    return () => {
      //  render nothing since we might have non-visual components
      //  e.g. layoutSettings
      return null;
    };
  }
  return componentsMap[componentName];
}
