import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { isGifUrl, isSvgUrl } from "~utils/asset_utils/asset_utils";
import { isEditorRoute } from "~utils/storyblok/storyblok";

import AssetsContext from "../../../assets_context";
import * as classes from "./remote_image.module.scss";

export default function RemoteImage({
  id,
  filename,
  alt,
  width,
  isAboveTheFold,
  objectFit,
}) {
  //  use context to pull it out of local assets
  const { assets } = useContext(AssetsContext);

  // get specific asset based on file id
  const asset = assets[`File_${id}`];

  const classNames = [classes.remoteImage];

  if (objectFit === "cover") {
    classNames.push(classes.cover);
  }

  // If no asset, assumed in editor
  if (!asset) {
    if (isEditorRoute() && filename) {
      // Display img tag with storyblok CDN src
      return <img className={classNames.join(" ")} src={filename} alt={alt} />;
    }

    // Error if image is missing or not in editor
    // If no filename defined, probably a new image component defined in the CMS
    return null;
  }

  if (isSvgUrl(asset.publicURL)) {
    return (
      <img className={classNames.join(" ")} src={asset.publicURL} alt={alt} />
    );
  }

  if (isGifUrl(asset.publicURL)) {
    return (
      // <AspectRatio ratio={asset?.fields?.aspectRatio}>
      <img
        id="gif"
        className={classNames.join(" ")}
        src={asset.publicURL}
        alt={alt}
      />
      // </AspectRatio>
    );
  }

  // Should bomb if childImageSharp is not defined
  if (!asset.childImageSharp) {
    return null;
  }

  if (width) {
    asset.childImageSharp.gatsbyImageData.sizes = `${width}px`;
  }

  return (
    <GatsbyImage
      image={asset?.childImageSharp?.gatsbyImageData}
      className={classNames.join(" ")}
      loading={isAboveTheFold ? "eager" : "lazy"}
      alt={alt === null ? "Image" : alt}
    />
  );
}

RemoteImage.defaultProps = {
  id: null,
  filename: null,
  alt: "",
  width: null,
  isAboveTheFold: false,
  objectFit: "contain",
};

RemoteImage.propTypes = {
  id: PropTypes.number,
  filename: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAboveTheFold: PropTypes.bool,
  objectFit: PropTypes.oneOf(["contain", "cover"]),
};
