/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { Interactive } from "react-interactive";
import { MARK_LINK, MARK_STYLED } from "storyblok-rich-text-react-renderer";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import StyledText from "../components/rich_text_styled_element/rich_text_styled_element";
import { linkStyle } from "../rich_text.module.scss";

// eslint-disable-next-line import/prefer-default-export
export const RichTextMarkResolvers = {
  [MARK_STYLED]: (children, attrs) => {
    return <StyledText classType={attrs.class}>{children}</StyledText>;
  },
  [MARK_LINK]: (children, attrs) => {
    return (
      <Interactive link={attrs} className={linkStyle} as={LinkWrapper}>
        {children}
      </Interactive>
    );
  },
};
