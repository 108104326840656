/* eslint-disable camelcase */
import PropTypes from "prop-types";
import React from "react";

import Contain from "~components/atoms/contain/contain";
import CSSTransitionComponent from "~components/utils/css_transition_component/css_transition_component";

import {
  desktopNavDropdownMenuInnerStyle,
  desktopNavDropdownMenuWrapperStyle,
} from "./desktop_nav_dropdown_wrapper.module.scss";
import {
  transitionExpand_enter,
  transitionExpand_enter_active,
  transitionExpand_enter_done,
  transitionExpand_exit,
  transitionExpand_exit_active,
  transitionExpand_exit_done,
} from "./desktop_nav_dropdown_wrapper_transitions.module.scss";

const TRANSITION_CLASSNAMES = {
  enter: transitionExpand_enter,
  enterActive: transitionExpand_enter_active,
  enterDone: transitionExpand_enter_done,
  exit: transitionExpand_exit,
  exitActive: transitionExpand_exit_active,
  exitDone: transitionExpand_exit_done,
};

const TIMEOUT = {
  appear: 0,
  enter: 400,
  exit: 400,
};

export default function DesktopNavDropdownWrapper({
  isActive,
  callbackOnOpenSubMenu,
  callbackOnCloseSubMenu,
  children,
}) {
  return (
    <CSSTransitionComponent
      isActive={isActive}
      classNames={TRANSITION_CLASSNAMES}
      timeout={TIMEOUT}
      onEnter={callbackOnOpenSubMenu}
      onExited={callbackOnCloseSubMenu}
    >
      <div className={desktopNavDropdownMenuWrapperStyle}>
        <Contain>
          <div className={desktopNavDropdownMenuInnerStyle}>{children}</div>
        </Contain>
      </div>
    </CSSTransitionComponent>
  );
}

DesktopNavDropdownWrapper.defaultProps = {
  isActive: null,
  callbackOnOpenSubMenu: () => {},
  callbackOnCloseSubMenu: () => {},
  children: null,
};

DesktopNavDropdownWrapper.propTypes = {
  isActive: PropTypes.bool,
  callbackOnOpenSubMenu: PropTypes.func,
  callbackOnCloseSubMenu: PropTypes.func,
  children: PropTypes.node,
};
