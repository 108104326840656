export default function flattenCategoryNavItems(inputArray) {
  return inputArray?.reduce((carry, currentItem) => {
    if (currentItem.children?.length > 0) {
      currentItem.children.map((subItem) => {
        if (
          subItem.component === "navLink" ||
          subItem.component === "navItem" // Todo(BSE): Remove 'navItem' once all components are converted to 'navLink'.
        ) {
          carry.push(subItem);
        }
        return null;
      });
    }

    if (
      currentItem.component === "navLink" ||
      currentItem.component === "navItem" // Todo(BSE): Remove 'navItem' once all components are converted to 'navLink'.
    ) {
      carry.push(currentItem);
    }
    return carry;
  }, []);
}
