import React, { useMemo } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Footer from "~components/organisms/footer/footer";
import Header from "~components/organisms/header/header";
import Metadata from "~components/utils/metadata/metadata";
import ThirdParty from "~components/utils/third_party/third_party";
import {
  getPageMetadata,
  parseStoryblokEntry,
} from "~utils/storyblok/storyblok";
import { AssetsContextProvider } from "../../assets_context";
import { ASSET, GLOBAL_PAGE_QUERY } from "../../prop_types";

export default function TemplateGlobalEntry({
  data,
  pageContext,
  entryAssets,
  children,
  headerColor,
  subNavData,
  pagePath,
  isListPage,
  programmaticPageTitle,
}) {
  const globalMetadata = useMemo(() => {
    return parseStoryblokEntry("globalMetadata", data.globalMetadata);
  }, [data.globalMetadata]);
  const story = useMemo(() => {
    return parseStoryblokEntry("story", data.story);
  }, [data.story]);
  const header = useMemo(() => {
    return parseStoryblokEntry("header", data.header);
  }, [data.header]);
  const footer = useMemo(() => {
    return parseStoryblokEntry("footer", data.footer);
  }, [data.footer]);
  const thirdParty = useMemo(() => {
    return parseStoryblokEntry("thirdParty", data.thirdParty);
  }, [data.thirdParty]);

  const { alternates } = data.story;
  const fullSlug = data.story.full_slug;

  // Todo(BSE): Align this across all templates.
  const { language, lang } = pageContext;

  // Extends global metadata with page level metadata to create custom
  // metadata for page (if defined)
  const metadata = useMemo(() => {
    const pageMetadata = story?.metadata?.[0] || {};

    return getPageMetadata(pageMetadata, globalMetadata);
  }, [story.metadata, globalMetadata]);

  return (
    <AssetsContextProvider
      entryAssets={entryAssets}
      storyAssets={data.story.assets}
      headerAssets={data.header.assets}
      footerAssets={data.footer.assets}
      metadataAssets={data.globalMetadata.assets}
      thirdPartyAssets={data.thirdParty.assets}
    >
      <Metadata
        {...metadata}
        alternates={alternates}
        fullSlug={fullSlug}
        language={language || lang}
        programmaticPagePath={pagePath}
        programmaticPageTitle={programmaticPageTitle}
        isListPage={isListPage}
      />
      <Header color={headerColor || story.headerColor} content={header} />
      <main>{children}</main>
      <Footer {...footer} language={language || lang} subNavData={subNavData} />
      <ThirdParty {...thirdParty} />
    </AssetsContextProvider>
  );
}

TemplateGlobalEntry.defaultProps = {
  entryAssets: null,
  headerColor: null,
  subNavData: null,
  pagePath: null,
  isListPage: false,
  programmaticPageTitle: null,
};

TemplateGlobalEntry.propTypes = {
  headerColor: PropTypes.oneOf(["white", "blue"]),
  subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  pagePath: PropTypes.string,
  programmaticPageTitle: PropTypes.string,
  isListPage: PropTypes.bool,
  data: GLOBAL_PAGE_QUERY.isRequired,
  entryAssets: PropTypes.arrayOf(ASSET),
  pageContext: PropTypes.shape({
    language: PropTypes.string,
    lang: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export const query = graphql`
  fragment story on Query {
    story: storyblokEntry(id: { eq: $id }) {
      assets {
        ...asset
      }
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  fragment asset on File {
    id
    publicURL
    fields {
      aspectRatio
    }
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }

  fragment globalMetadata on Query {
    globalMetadata: storyblokEntry(
      field_component: { eq: "metadata" }
      full_slug: { regex: $langRegex }
    ) {
      assets {
        ...asset
      }
      content
    }
  }

  fragment resourceHubStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  fragment header on Query {
    header: storyblokEntry(
      field_component: { eq: "header" }
      full_slug: { regex: $langRegex }
    ) {
      assets {
        ...asset
      }
      content
    }
  }

  fragment footer on Query {
    footer: storyblokEntry(
      field_component: { eq: "footer" }
      full_slug: { regex: $langRegex }
    ) {
      assets {
        ...asset
      }
      content
    }
  }

  fragment thirdParty on Query {
    thirdParty: storyblokEntry(
      field_component: { eq: "thirdParty" }
      full_slug: { regex: $langRegex }
    ) {
      assets {
        ...asset
      }
      content
    }
  }

  fragment resourceHubCoverAssets on Query {
    assets: allFile(filter: { id: { in: $allCoverIDs } }) {
      nodes {
        id
        publicURL
        fields {
          aspectRatio
        }
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  }

  fragment global on Query {
    ...globalMetadata
    ...header
    ...footer
    ...thirdParty
  }
`;
