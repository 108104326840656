// extracted by mini-css-extract-plugin
export var buttonStyle = "button_tertiary-module--buttonStyle--yj9Rz";
export var colorAzureStyle = "button_tertiary-module--colorAzureStyle--FKizQ";
export var colorCloudBurst50Style = "button_tertiary-module--colorCloudBurst50Style--egy4W";
export var colorCloudBurst80Style = "button_tertiary-module--colorCloudBurst80Style--CQw1B";
export var colorCloudBurstStyle = "button_tertiary-module--colorCloudBurstStyle--cRDKP";
export var colorDarkVioletStyle = "button_tertiary-module--colorDarkVioletStyle--esiBX";
export var colorElectricVioletStyle = "button_tertiary-module--colorElectricVioletStyle--EKBVX";
export var colorSunStyle = "button_tertiary-module--colorSunStyle--kxVZr";
export var colorWhiteStyle = "button_tertiary-module--colorWhiteStyle--WHgJI";
export var disabledStyle = "button_tertiary-module--disabledStyle--jCxZR";
export var isActiveStyle = "button_tertiary-module--isActiveStyle--lj4Gl";
export var isCenteredStyle = "button_tertiary-module--isCenteredStyle--AaQRv";
export var isDisabledStyle = "button_tertiary-module--isDisabledStyle--cnUuf";
export var isFontBoldStyle = "button_tertiary-module--isFontBoldStyle--HEbME";
export var isFontRegularStyle = "button_tertiary-module--isFontRegularStyle--3R67N";
export var isInteractiveStyle = "button_tertiary-module--isInteractiveStyle--MCp1-";
export var isUnderlinedStyle = "button_tertiary-module--isUnderlinedStyle--Qyvoy";
export var isUpperCaseStyle = "button_tertiary-module--isUpperCaseStyle--61T6q";
export var marginBottomLgStyle = "button_tertiary-module--marginBottomLgStyle--RwE6V";
export var marginBottomMdStyle = "button_tertiary-module--marginBottomMdStyle--ybdvh";
export var marginBottomNoneStyle = "button_tertiary-module--marginBottomNoneStyle--epSK9";
export var marginBottomSmStyle = "button_tertiary-module--marginBottomSmStyle--2gMQp";
export var marginBottomXsStyle = "button_tertiary-module--marginBottomXsStyle--LaDSx";
export var marginLeftLgStyle = "button_tertiary-module--marginLeftLgStyle--8nv+b";
export var marginLeftMdStyle = "button_tertiary-module--marginLeftMdStyle--ouxgZ";
export var marginLeftNoneStyle = "button_tertiary-module--marginLeftNoneStyle--oe5MA";
export var marginLeftSmStyle = "button_tertiary-module--marginLeftSmStyle--1nsxg";
export var marginLeftXsStyle = "button_tertiary-module--marginLeftXsStyle--YPwYE";
export var marginRightLgStyle = "button_tertiary-module--marginRightLgStyle--+sqhe";
export var marginRightMdStyle = "button_tertiary-module--marginRightMdStyle--h3P0U";
export var marginRightNoneStyle = "button_tertiary-module--marginRightNoneStyle--dJf6j";
export var marginRightSmStyle = "button_tertiary-module--marginRightSmStyle--OliA3";
export var marginRightXsStyle = "button_tertiary-module--marginRightXsStyle--Ma6kl";
export var marginTopLgStyle = "button_tertiary-module--marginTopLgStyle--vu0WT";
export var marginTopMdStyle = "button_tertiary-module--marginTopMdStyle--Rtuu8";
export var marginTopNoneStyle = "button_tertiary-module--marginTopNoneStyle--8-F03";
export var marginTopSmStyle = "button_tertiary-module--marginTopSmStyle--aKAOm";
export var marginTopXsStyle = "button_tertiary-module--marginTopXsStyle--ajUbo";
export var noWrapStyle = "button_tertiary-module--noWrapStyle--FlFve";
export var spaceBetweenStyle = "button_tertiary-module--spaceBetweenStyle--lY3i0";