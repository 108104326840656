/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import { render } from "storyblok-rich-text-react-renderer";
import { COLOR, RICH_TEXT_CONTENT } from "../../../prop_types";
import {
  colorWhiteStyle,
  isCenteredStyle,
  richTextStyle,
  sizeExtraSmallStyle,
  sizeLargeStyle,
  sizeSmallStyle,
  standFirstStyle,
} from "./rich_text.module.scss";
import { RichTextMarkResolvers } from "./rich_text_render_configuration/rich_text_mark_resolvers";
import RichTextNodeResolver from "./rich_text_render_configuration/rich_text_node_resolvers";

export default function RichText({
  balanceText,
  color,
  isCentered,
  seoHeadingLevel,
  size,
  text,
  ...rest
}) {
  const richTextClassNames = classNames(richTextStyle, {
    [isCenteredStyle]: isCentered,
    [sizeExtraSmallStyle]: size === "x-small",
    [sizeSmallStyle]: size === "small",
    [sizeLargeStyle]: size === "large",
    [standFirstStyle]: size === "xl",
    [colorWhiteStyle]: color === "white",
  });

  // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
  const style = {};

  if (color && color.color) {
    style.color = color.color;
  }

  const Element = seoHeadingLevel ? `h${seoHeadingLevel}` : "div";

  return (
    <SbEditable content={rest}>
      <Element
        data-testid="rich-text"
        className={richTextClassNames}
        style={style}
      >
        {text &&
          render(text, {
            nodeResolvers: RichTextNodeResolver({ balanceText }),
            markResolvers: RichTextMarkResolvers,
          })}
      </Element>
    </SbEditable>
  );
}

RichText.propTypes = {
  balanceText: PropTypes.bool,
  color: PropTypes.oneOfType([COLOR, PropTypes.string]),
  isCentered: PropTypes.bool,
  seoHeadingLevel: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.shape({
    content: PropTypes.arrayOf(RICH_TEXT_CONTENT),
    type: PropTypes.string,
  }),
};

RichText.defaultProps = {
  balanceText: false,
  color: null,
  isCentered: false,
  seoHeadingLevel: null,
  size: "regular",
  text: null,
};
