import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

import HideOnScrollContext from "../../../../../hide_on_scroll_context";
import {
  hiddenStyle,
  hideOnScrollInnerStyle,
  hideOnScrollWrapperStyle,
} from "./hide_on_scroll_wrapper.module.scss";

export default function HideOnScrollWrapper({
  children,
  callbackWhenScrolled,
}) {
  const { isScrolledToTop, shouldHideHeader } = useContext(HideOnScrollContext);

  const hideOnScrollWrapperClassNames = classNames(
    hideOnScrollWrapperStyle,
    {}
  );
  const hideOnScrollInnerClassNames = classNames(hideOnScrollInnerStyle, {
    [hiddenStyle]: shouldHideHeader && !isScrolledToTop,
  });

  useEffect(() => {
    if (callbackWhenScrolled) {
      return callbackWhenScrolled(!isScrolledToTop);
    }
    return null;
  }, [isScrolledToTop, callbackWhenScrolled]);

  return (
    <div className={hideOnScrollWrapperClassNames}>
      <div className={hideOnScrollInnerClassNames}>{children}</div>
    </div>
  );
}
HideOnScrollWrapper.defaultProps = {
  callbackWhenScrolled: null,
};
HideOnScrollWrapper.propTypes = {
  callbackWhenScrolled: PropTypes.func,
  children: PropTypes.node.isRequired,
};
