import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import { ASSET } from "./prop_types";

const Context = React.createContext({});

function formatAssets(...args) {
  return args.reduce((carry, arg) => {
    if (Array.isArray(arg)) {
      arg.forEach(({ id, ...asset }) => {
        if (!carry[id]) {
          // eslint-disable-next-line no-param-reassign
          carry[id] = asset;
        }
      });
    }
    return carry;
  }, {});
}

export function AssetsContextProvider({
  entryAssets,
  storyAssets,
  headerAssets,
  footerAssets,
  metadataAssets,
  thirdPartyAssets,
  children,
}) {
  const [assetsState, setAssetsState] = useState(
    formatAssets(
      entryAssets,
      storyAssets,
      headerAssets,
      footerAssets,
      metadataAssets,
      thirdPartyAssets
    )
  );

  const value = useMemo(() => {
    const addAssets = (newAssets) => {
      setAssetsState({
        ...assetsState,
        ...formatAssets(newAssets),
      });
    };
    return {
      assets: assetsState,
      addAssets,
    };
  }, [assetsState]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

AssetsContextProvider.defaultProps = {
  entryAssets: null,
  storyAssets: null,
  headerAssets: null,
  footerAssets: null,
  metadataAssets: null,
  thirdPartyAssets: null,
};

AssetsContextProvider.propTypes = {
  entryAssets: PropTypes.arrayOf(ASSET),
  storyAssets: PropTypes.arrayOf(ASSET),
  headerAssets: PropTypes.arrayOf(ASSET),
  footerAssets: PropTypes.arrayOf(ASSET),
  metadataAssets: PropTypes.arrayOf(ASSET),
  thirdPartyAssets: PropTypes.arrayOf(ASSET),
  children: PropTypes.node.isRequired,
};

export default Context;
