import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import NavLink from "~components/molecules/nav_link/nav_link";
import flattenCategoryNavItems from "./helper_functions/flatten_category_nav_items/flatten_category_nav_items";
import { titleStyle, wrapperStyle } from "./mobile_nav_category.module.scss";

export default function MobileNavCategory({
  title,
  children,
  callbackOnClickItem,
}) {
  const categoryNavItems = useMemo(() => {
    return flattenCategoryNavItems(children);
  }, [children]);

  return (
    <div className={wrapperStyle}>
      {title && (
        <div className={titleStyle}>
          <Heading visualLevel={7} isUppercase>
            {title}
          </Heading>
        </div>
      )}
      {categoryNavItems?.length > 0 &&
        categoryNavItems.map((item) => {
          return (
            <NavLink {...item} onClick={callbackOnClickItem} key={item._uid} />
          );
        })}
    </div>
  );
}

MobileNavCategory.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  callbackOnClickItem: () => {},
};

MobileNavCategory.defaultProps = {
  children: null,
  title: null,
  callbackOnClickItem: PropTypes.func,
};
