import { removeDefaultLanguagePrefix } from "~utils/url_utils";

// eslint-disable-next-line import/prefer-default-export
export function getLinkPropsAsset(link) {
  let internalUrl = link.story?.url || link.href;

  // Ensure the internal link begins with a "/"
  if (!internalUrl.startsWith("/")) {
    internalUrl = `/${internalUrl}`;
  }

  internalUrl = removeDefaultLanguagePrefix(internalUrl);

  // Remove the default language prefix
  if (internalUrl.startsWith("/en-gb/")) {
    internalUrl = internalUrl.replace("/en-gb/", "/");
  }

  return {
    target: "_blank",
    to: internalUrl,
    download: true,
    type: "asset",
  };
}
