import React from "react";
import PropTypes from "prop-types";
import getEditorClassnames from "./helper_functions/get_editor_classnames/get_editor_classnames";

export default function RichTextStyledElement({ classType, children }) {
  const editorControlledClassname = getEditorClassnames({ classType });

  if (editorControlledClassname) {
    return <span className={editorControlledClassname}>{children}</span>;
  }

  return children;
}

RichTextStyledElement.propTypes = {
  classType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RichTextStyledElement.defaultProps = {
  classType: "",
};
