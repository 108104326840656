import React from "react";

import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { removeDefaultLanguagePrefix } from "~utils/url_utils/remove_default_language_prefix/remove_default_language_prefix";

import { getLanguageForEntry } from "../../../../../../utils";

export default function createAlternateLink({ alternate, origin, isListPage }) {
  if (isListPage) {
    return "";
  }

  const alternateUrl = createUrlPathFromArray([origin, alternate.full_slug]);
  const lang = getLanguageForEntry(alternate);

  const sanitisedAlternateUrl = removeDefaultLanguagePrefix(alternateUrl);
  return (
    <link
      key={`${lang}`}
      rel="alternate"
      href={sanitisedAlternateUrl}
      hrefLang={lang}
    />
  );
}
