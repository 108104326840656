import React, { createRef } from "react";

import PropTypes from "prop-types";

import useClickOutside from "~hooks/use_click_outside/use_click_outside";

import useDebouncedHoverInteractions from "./debounced_focus_wrapper_hooks/use_debounced_hover_interaction/use_debounced_hover_interaction";

function DebouncedFocusWrapper({
  callbackWhenBlurred,
  callbackWhenFocused,
  children,
  enterDelay,
  leaveDelay,
}) {
  const rootRef = createRef();

  const { handleHover, handleLeave } = useDebouncedHoverInteractions({
    hoverCallback: callbackWhenFocused,
    leaveCallback: callbackWhenBlurred,
    enterDelay,
    leaveDelay,
  });

  useClickOutside(rootRef, callbackWhenBlurred);

  return (
    <div
      ref={rootRef}
      onMouseEnter={handleHover}
      onFocus={handleHover}
      onBlur={handleLeave}
      onMouseLeave={handleLeave}
    >
      {children}
    </div>
  );
}

DebouncedFocusWrapper.propTypes = {
  callbackWhenBlurred: PropTypes.func,
  callbackWhenFocused: PropTypes.func,
  children: PropTypes.node,
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number,
  wrapperMargin: PropTypes.shape({}),
};

DebouncedFocusWrapper.defaultProps = {
  callbackWhenBlurred: () => {},
  callbackWhenFocused: () => {},
  children: null,
  enterDelay: 0,
  leaveDelay: 0,
  wrapperMargin: {},
};

export default DebouncedFocusWrapper;
