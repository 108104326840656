import PropTypes from "prop-types";
import React from "react";

import Image from "~components/atoms/image/image";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";

import { LINK, MEDIA } from "../../../../../prop_types";
import * as classes from "./home_button.module.scss";

export default function NavHomeButton({
  shouldUseWhiteLogoText,
  logoWhite,
  logoCloudBurst,
  logomarkWhite,
  logomarkSun,
  logoLink,
}) {
  const logoLarge = shouldUseWhiteLogoText ? logoWhite : logoCloudBurst;

  const logoSmall = shouldUseWhiteLogoText ? logomarkWhite : logomarkSun;

  return (
    <LinkWrapper data-testid="nav-home-button" link={logoLink}>
      <div className={classes.homeButton}>
        <div className={classes.large}>
          <Image image={logoLarge} width={167} height={32} />
        </div>
        <div className={classes.small}>
          <Image image={logoSmall} width={32} height={32} />
        </div>
      </div>
    </LinkWrapper>
  );
}
NavHomeButton.defaultProps = {
  shouldUseWhiteLogoText: false,
  logoCloudBurst: null,
  logoWhite: null,
  logomarkSun: null,
  logomarkWhite: null,
  logoLink: null,
};

NavHomeButton.propTypes = {
  logoCloudBurst: MEDIA,
  logoWhite: MEDIA,
  logomarkSun: MEDIA,
  logomarkWhite: MEDIA,
  logoLink: LINK,
  shouldUseWhiteLogoText: PropTypes.bool,
};
