import React, { forwardRef, useCallback } from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import PropTypes from "prop-types";
import trackOutboundLinkToConsole from "~utils/google_analytics/track_outbound_link_to_console/track_outbound_link_to_console";
import { getLinkProps } from "~utils/link_utils/getLinkProps/getLinkProps";
import { LINK } from "../../../prop_types";
import * as classes from "./link_wrapper.module.scss";

// ToDo: [MW-233] test link wrapper component
const LinkWrapper = forwardRef(
  (
    { link, onClick, children, block, stretch, isDisabled, ...rest },
    // eslint-disable-next-line no-unused-vars
    ref
  ) => {
    const linkProps = getLinkProps(link);

    const trackOutboundClick = useCallback(() => {
      if (linkProps?.href) {
        trackOutboundLinkToConsole(linkProps.href);
      }
      if (onClick) {
        return onClick;
      }
      return null;
    }, [linkProps.href, onClick]);

    //  If is not a link, renders a span wrapper around children instead
    const isLink = !isDisabled && link && (linkProps.to || linkProps.href);

    const linkClassNames = classNames(classes.link, {
      [classes.block]: block,
      [classes.stretch]: stretch,
      [classes.isDisabledStyle]: isDisabled,
    });

    if (isLink && linkProps.type === "anchorLink") {
      return (
        <AnchorLink
          className={linkClassNames}
          {...linkProps}
          onAnchorLinkClick={onClick}
          stripHash
          data-cy="link_wrapper"
          {...rest}
        >
          {children}
        </AnchorLink>
      );
    }

    if (isLink && linkProps.type === "internalLink") {
      return (
        <Link
          className={linkClassNames}
          {...linkProps}
          onClick={onClick}
          data-cy="link_wrapper"
          {...rest}
        >
          {children}
        </Link>
      );
    }

    if (
      isLink &&
      (linkProps.type === "externalLink" || linkProps.type === "emailLink")
    ) {
      return (
        <OutboundLink
          className={linkClassNames}
          {...linkProps}
          {...rest}
          onClick={trackOutboundClick}
          data-cy="link_wrapper"
        >
          {children}
        </OutboundLink>
      );
    }

    return <span {...rest}>{children}</span>;
  }
);

export default LinkWrapper;

LinkWrapper.defaultProps = {
  link: null,
  children: null,
  onClick: null,
  block: false,
  isDisabled: false,
  stretch: false,
};

LinkWrapper.propTypes = {
  link: LINK,
  children: PropTypes.node,
  onClick: PropTypes.func,
  block: PropTypes.bool,
  isDisabled: PropTypes.bool,
  stretch: PropTypes.bool,
};
