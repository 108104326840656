export default function getSocialShareImageSlug({ assets, image, origin }) {
  if (image?.id) {
    if (assets[`File_${image.id}`]) {
      const socialShareImageAsset = assets[`File_${image.id}`];

      const { publicURL } = socialShareImageAsset;

      const socialShareImageSlug = `${origin}${publicURL}`;

      return socialShareImageSlug;
    }
  }
  return null;
}
