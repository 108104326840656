import React from "react";
import getComponent from "./helper_functions/get_component/get_component";

export default function renderDynamicComponent({
  parentComponentName,
  componentName,
  componentsMap,
  _uid,
  ...rest
}) {
  if (!componentName || !componentsMap) {
    return null;
  }
  return React.createElement(
    getComponent({
      parentComponentName,
      componentName,
      componentsMap,
      ...rest,
    }),
    {
      key: _uid,
      blok: componentName,
      isStoryblok: true,
      ...rest,
    }
  );
}
