import React from "react";
import PropTypes from "prop-types";
import { BLOK_ARRAY } from "../../../../../../../prop_types";
import FooterColumnSection from "../footer_column_section/footer_column_section";

export default function FooterColumn({
  children,
  shouldShowDynamicBlogList,
  categories,
}) {
  const [firstChildComponent] = children || [];

  const columnContainsDynamicBlogList =
    firstChildComponent?.component === "footerBlogCategoryList";

  if (columnContainsDynamicBlogList && shouldShowDynamicBlogList === false) {
    return null;
  }
  if (columnContainsDynamicBlogList && shouldShowDynamicBlogList) {
    return (
      <div>
        <FooterColumnSection {...categories} />
      </div>
    );
  }

  return (
    <div>
      {children.map((item) => {
        return <FooterColumnSection key={item.title} {...item} />;
      })}
    </div>
  );
}

FooterColumn.defaultProps = {
  children: null,
  shouldShowDynamicBlogList: false,
  categories: {},
};

FooterColumn.propTypes = {
  children: BLOK_ARRAY,
  shouldShowDynamicBlogList: PropTypes.bool,
  categories: PropTypes.shape({}),
};
