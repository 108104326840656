import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import BalanceTextComponent from "~components/utils/balance_text_component/balance_text_component";
import { BLOK_ARRAY, COLOR } from "../../../prop_types";
import {
  azureStyle,
  cloudBurstStyle,
  grayStyle,
  heading1Style,
  heading2Style,
  heading3Style,
  heading4Style,
  heading5Style,
  heading6Style,
  heading7Style,
  isBaseFontWeightStyle,
  isCenteredStyle,
  isExtraBoldStyle,
  isUppercaseStyle,
  isXXLStyle,
  marginBottomLgStyle,
  marginBottomMdStyle,
  marginBottomNoneStyle,
  marginBottomSmStyle,
  marginBottomXsStyle,
  marginLeftLgStyle,
  marginLeftMdStyle,
  marginLeftNoneStyle,
  marginLeftSmStyle,
  marginLeftXsStyle,
  marginRightLgStyle,
  marginRightMdStyle,
  marginRightNoneStyle,
  marginRightSmStyle,
  marginRightXsStyle,
  marginTopLgStyle,
  marginTopMdStyle,
  marginTopNoneStyle,
  marginTopSmStyle,
  marginTopXsStyle,
  noMarginStyle,
  noWrapStyle,
  whiteStyle,
} from "./heading.module.scss";

export default function Heading({
  balanceText,
  children,
  color,
  id,
  isCentered,
  isBaseFontWeight,
  isUppercase,
  isXXL,
  noMargin,
  noWrap,
  opacity,
  seoLevel,
  settings,
  text,
  visualLevel,
  isExtraBold,
  margin,
  ...rest
}) {
  /* ——————————————————————————————————————————————————————————————————————————————
  //      COMPONENT LOGIC                                                         
  // —————————————————————————————————————————————————————————————————————————————— */
  const Element = `h${seoLevel}`;

  // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
  const style = {};

  if (opacity) {
    style.opacity = opacity;
  }

  if (color && color.color) {
    style.color = color.color;
  }

  const { "data-testid": testId } = rest;

  /* ——————————————————————————————————————————————————————————————————————————————
  //      STYLES                                                                  
  // —————————————————————————————————————————————————————————————————————————————— */
  const headingClassnames = classNames({
    /* ——————————————————————————————————————————————————————————————
    //      HIERARCHAL HEADING STYLES
    // —————————————————————————————————————————————————————————————— */
    [heading1Style]: visualLevel === 1 || visualLevel === "1",
    [heading2Style]: visualLevel === 2 || visualLevel === "2",
    [heading3Style]: visualLevel === 3 || visualLevel === "3",
    [heading4Style]: visualLevel === 4 || visualLevel === "4",
    [heading5Style]: visualLevel === 5 || visualLevel === "5",
    [heading6Style]: visualLevel === 6 || visualLevel === "6",
    [heading7Style]: visualLevel === 7 || visualLevel === "7",
    /* ——————————————————————————————————————————————————————————————
    //      OVERRIDE STYLES                                           
    // —————————————————————————————————————————————————————————————— */
    [isUppercaseStyle]: isUppercase,
    [isCenteredStyle]: isCentered,
    [isXXLStyle]: isXXL,
    [isExtraBoldStyle]: isExtraBold,
    [isBaseFontWeightStyle]: isBaseFontWeight,
    [noMarginStyle]: noMargin,
    [noWrapStyle]: noWrap,
    /* ——————————————————————————————————————————————————————————————
    //      COLOR STYLES                                           
    // —————————————————————————————————————————————————————————————— */
    [whiteStyle]: color === "white",
    [grayStyle]: color === "gray",
    [cloudBurstStyle]: color === "cloudBurst",
    [azureStyle]: color === "azure",
    /* ——————————————————————————————————————————————————————————————
    //      MARGIN STYLES                                           
    // —————————————————————————————————————————————————————————————— */
    // Using native scss modules plays better with SSR and reduces layout shift
    // Allowing per-component margin styles rather than a global style to allow granular control

    /* ——————————————————————————————————————————————
    //      MARGIN TOP STYLES                       
    // —————————————————————————————————————————————— */
    [marginTopLgStyle]: margin.marginTop === "lg",
    [marginTopMdStyle]: margin.marginTop === "md",
    [marginTopSmStyle]: margin.marginTop === "sm",
    [marginTopXsStyle]: margin.marginTop === "xs",
    [marginTopNoneStyle]: margin.marginTop === "none",
    /* ——————————————————————————————————————————————
    //      MARGIN BOTTOM STYLES                       
    // —————————————————————————————————————————————— */
    [marginBottomLgStyle]: margin.marginBottom === "lg",
    [marginBottomMdStyle]: margin.marginBottom === "md",
    [marginBottomSmStyle]: margin.marginBottom === "sm",
    [marginBottomXsStyle]: margin.marginBottom === "xs",
    [marginBottomNoneStyle]: margin.marginBottom === "none",
    /* ——————————————————————————————————————————————
    //      MARGIN LEFT STYLES                       
    // —————————————————————————————————————————————— */
    [marginLeftLgStyle]: margin.marginLeft === "lg",
    [marginLeftMdStyle]: margin.marginLeft === "md",
    [marginLeftSmStyle]: margin.marginLeft === "sm",
    [marginLeftXsStyle]: margin.marginLeft === "xs",
    [marginLeftNoneStyle]: margin.marginLeft === "none",
    /* ——————————————————————————————————————————————
    //      MARGIN RIGHT STYLES                       
    // —————————————————————————————————————————————— */
    [marginRightLgStyle]: margin.marginRight === "lg",
    [marginRightMdStyle]: margin.marginRight === "md",
    [marginRightSmStyle]: margin.marginRight === "sm",
    [marginRightXsStyle]: margin.marginRight === "xs",
    [marginRightNoneStyle]: margin.marginRight === "none",
  });

  /* ——————————————————————————————————————————————————————————————————————————————
  //      RETURN                                                         
  // —————————————————————————————————————————————————————————————————————————————— */
  if (balanceText) {
    return (
      <SbEditable content={rest}>
        <Element
          data-testid={testId}
          id={id}
          className={headingClassnames}
          style={style}
        >
          <BalanceTextComponent>{text || children}</BalanceTextComponent>
        </Element>
      </SbEditable>
    );
  }
  return (
    <SbEditable content={rest}>
      <Element
        data-testid={testId}
        id={id}
        className={headingClassnames}
        style={style}
      >
        {text || children}
      </Element>
    </SbEditable>
  );
}

/* ——————————————————————————————————————————————————————————————————————————————
//      PROP TYPES                                                         
// —————————————————————————————————————————————————————————————————————————————— */
Heading.propTypes = {
  balanceText: PropTypes.bool,
  children: PropTypes.node,
  color: PropTypes.oneOfType([COLOR, PropTypes.string]),
  id: PropTypes.string,
  isExtraBold: PropTypes.bool,
  isCentered: PropTypes.bool,
  isBaseFontWeight: PropTypes.bool,
  isUppercase: PropTypes.bool,
  margin: PropTypes.shape({
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    marginTop: PropTypes.string,
  }),
  isXXL: PropTypes.bool,
  noMargin: PropTypes.bool,
  opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seoLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  settings: BLOK_ARRAY,
  text: PropTypes.string,
  visualLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  noWrap: PropTypes.bool,
};

Heading.defaultProps = {
  balanceText: false,
  children: null,
  color: null,
  id: null,
  isExtraBold: true,
  isBaseFontWeight: false,
  isCentered: false,
  isUppercase: false,
  isXXL: false,
  noMargin: false,
  margin: {
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    marginTop: null,
  },
  opacity: null,
  seoLevel: "3",
  settings: null,
  text: "",
  noWrap: false,
};
