/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import {
  NODE_HEADING,
  NODE_PARAGRAPH,
} from "storyblok-rich-text-react-renderer";
import Heading from "~components/atoms/heading/heading";
import BalanceTextComponent from "~components/utils/balance_text_component/balance_text_component";
import { paragraphStyle } from "../rich_text.module.scss";

export default function RichTextNodeResolvers({ balanceText }) {
  return {
    [NODE_HEADING]: (children, { level }) => {
      return (
        <Heading
          balanceText={balanceText}
          seoLevel={level}
          visualLevel={level + 2}
        >
          {children}
        </Heading>
      );
    },
    [NODE_PARAGRAPH]: (children) => {
      if (balanceText) {
        return (
          <span className={paragraphStyle}>
            <BalanceTextComponent>{children}</BalanceTextComponent>
          </span>
        );
      }
      return <span className={paragraphStyle}>{children}</span>;
    },
  };
}
