import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Contain from "~components/atoms/contain/contain";
import useLockedBody from "~hooks/use_locked_body/use_locked_body";
import { BLOK_ARRAY } from "../../../../../../../prop_types";
import * as transitionScaleFadeAppearLeft from "../../../../../../../styles/global_scss_modules/global_css_transition_styles/transition_scale_fade_appear_left.module.scss";
import * as transitionScaleFadeAppearRight from "../../../../../../../styles/global_scss_modules/global_css_transition_styles/transition_scale_fade_appear_right.module.scss";
import * as transitionScaleFadeAppearTop from "../../../../../../../styles/global_scss_modules/global_css_transition_styles/transition_scale_fade_appear_top.module.scss";
import DesktopNavDropdownMenu from "../desktop_nav_dropdown_menu/desktop_nav_dropdown_menu";
import {
  desktopNavDropdownMenuWrapperStyle,
  isActiveStyle,
} from "./desktop_nav_dropdown_controller.module.scss";
import getItemsWithDropdown from "./helper_functions/get_items_with_dropdown/get_items_with_dropdown";
import getNavTransition from "./helper_functions/get_nav_transition/get_nav_transition";

const TRANSITION_CLASSNAMES = {
  left: { ...transitionScaleFadeAppearRight },
  right: { ...transitionScaleFadeAppearLeft },
  default: { ...transitionScaleFadeAppearTop },
};
const TRANSITION_TIMEOUT = 300;

export default function DesktopNavDropdownController({
  items,
  isMobileMenuOpen,
  currentStep,
  prevStep,
}) {
  const [height, setHeight] = useState(0);
  const innerRef = useRef();
  const dropdownClassnames = classNames(desktopNavDropdownMenuWrapperStyle, {
    [isActiveStyle]: currentStep !== null,
  });

  // Lock background from scrolling when menu is open
  const setLocked = useLockedBody();

  useEffect(() => {
    setLocked(currentStep !== null);
  }, [currentStep, setLocked]);

  const itemsWithDropdownMap = useMemo(() => {
    return getItemsWithDropdown({ items });
  }, [items]);

  // ———— UPDATE TRANSITION ON COMPONENT MOUNT/UNMOUNT ———
  // This can't be abstracted out (easily) as it is called
  // with a single positional argument by `Interactive` component
  const wrapChildWithNewTransitionProps = useCallback(
    (child) => {
      const transition = getNavTransition({
        currentStep,
        prevStep,
        transitionClassnames: TRANSITION_CLASSNAMES,
      });

      return React.cloneElement(child, {
        classNames: transition,
      });
    },
    [currentStep, prevStep]
  );

  // set height of morphing background shape
  useEffect(() => {
    if (
      currentStep !== null &&
      innerRef.current &&
      itemsWithDropdownMap[currentStep]
    ) {
      const boundingRect = innerRef.current.getBoundingClientRect();
      return setHeight(Math.floor(boundingRect.height));
    }
    return setHeight(0);
  }, [currentStep, itemsWithDropdownMap]);

  if (isMobileMenuOpen) {
    return null;
  }

  return (
    <div style={{ height }} className={dropdownClassnames}>
      <TransitionGroup childFactory={wrapChildWithNewTransitionProps}>
        {items?.length > 0 &&
          items.map((item, index) => {
            if (item.component === "navGroup" && currentStep === index) {
              return (
                <CSSTransition
                  key={item.title}
                  timeout={TRANSITION_TIMEOUT}
                  appear
                  unmountOnExit
                >
                  <div ref={innerRef}>
                    <Contain>
                      <DesktopNavDropdownMenu {...item} />
                    </Contain>
                  </div>
                </CSSTransition>
              );
            }
            return null;
          })}
      </TransitionGroup>
    </div>
  );
}

DesktopNavDropdownController.defaultProps = {
  items: null,
  isHeaderScrolled: false,
  isMobileMenuOpen: false,
  currentStep: null,
  prevStep: null,
};

DesktopNavDropdownController.propTypes = {
  items: BLOK_ARRAY,
  isHeaderScrolled: PropTypes.bool,
  isMobileMenuOpen: PropTypes.bool,
  currentStep: PropTypes.number,
  prevStep: PropTypes.number,
};
