import {
  inlineCodeStyle,
  standFirstStyle,
} from "../../../../rich_text.module.scss";

const RICH_TEXT_CLASSNAMES_MAP = {
  inlineCode: inlineCodeStyle,
  // Todo(BSE): Align these across our content types
  standFirst: standFirstStyle,
  standfirst: standFirstStyle,
  xl: standFirstStyle,
};

export default function getEditorClassnames({ classType }) {
  return RICH_TEXT_CLASSNAMES_MAP[classType];
}
