const SCREEN_SIZES = {
  XXL: "1178",
  XL: "1080",
  L: "920",
  M: "768",
  S: "576",
  XS: "360",
};

export default function getMediaQuery({ min, max }) {
  const minBreakpoint = SCREEN_SIZES[min];
  const maxBreakpoint = SCREEN_SIZES[max] - 1;

  if (min && minBreakpoint && max && maxBreakpoint) {
    return `(min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint}px)`;
  }

  if (min && minBreakpoint) {
    return `(min-width: ${minBreakpoint}px)`;
  }

  if (max && maxBreakpoint) {
    return `(max-width: ${maxBreakpoint}px)`;
  }

  return null;
}
