import PropTypes from "prop-types";
import React from "react";

import GridNew from "~components/atoms/grid_new/grid_new";

import { BLOK_ARRAY } from "../../../../../prop_types";
import FooterColumn from "./footer_links_section_components/footer_column/footer_column";

const GRID_SPLIT_LINKS_SECTION_4 = { XXL: 4, M: 2, XXS: 1 };
const GRID_SPLIT_LINKS_SECTION_3 = { XXL: 3, M: 1 };

export default function FooterLinksSection({ sections, subNavData }) {
  const categories = subNavData?.find((item) => {
    return item.slug === "categories";
  });

  const shouldShowDynamicBlogList = categories?.items?.length > 0;

  const sectionsGridSplit = shouldShowDynamicBlogList
    ? GRID_SPLIT_LINKS_SECTION_4
    : GRID_SPLIT_LINKS_SECTION_3;

  return (
    <GridNew split={sectionsGridSplit}>
      {sections?.length > 0 &&
        sections.map((column) => {
          return (
            <FooterColumn
              key={column.label}
              {...column}
              categories={categories}
              shouldShowDynamicBlogList={shouldShowDynamicBlogList}
            />
          );
        })}
    </GridNew>
  );
}

FooterLinksSection.defaultProps = {
  sections: [],
  subNavData: [],
};

FooterLinksSection.propTypes = {
  sections: BLOK_ARRAY,
  subNavData: PropTypes.arrayOf(PropTypes.shape({})),
};
