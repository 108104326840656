import PropTypes from "prop-types";
import React from "react";

import * as classes from "./chevron_small.module.scss";

export default function ChevronSmall({ linktype, isActive, chevronType }) {
  const classNames = [];

  let shouldShowExternalLink = false;

  // This is done this way so the optional chevronType prop
  // can override the inferred prop from the linktype
  // ToDo: [MW-301] Revisit stupid ordered if statements

  if (linktype === "url") {
    shouldShowExternalLink = true;
  }
  if (linktype === "story") {
    shouldShowExternalLink = false;
  }
  if (chevronType === "chevronExternalLink") {
    shouldShowExternalLink = true;
  }
  if (chevronType === "chevronRight") {
    shouldShowExternalLink = false;
  }

  if (isActive) {
    classNames.push(classes.isActive);
  }
  if (chevronType === "chevronRightHollow") {
    classNames.push(classes.chevronRightHollow);
    return (
      <svg className={classNames.join(" ")}>
        <use xlinkHref="/images/icon_chevron_right.svg#icon" />
      </svg>
    );
  }
  if (chevronType === "chevronDown") {
    classNames.push(classes.chevronDown);
    return (
      <svg className={classNames.join(" ")}>
        <use xlinkHref="/images/icon_plus.svg#icon" />
      </svg>
    );
  }
  if (!shouldShowExternalLink) {
    classNames.push(classes.chevronRight);
    return (
      <svg className={classNames.join(" ")}>
        <use xlinkHref="/images/icon_caret_right.svg#icon" />
      </svg>
    );
  }

  if (shouldShowExternalLink) {
    classNames.push(classes.chevronExternalLink);
    return (
      <svg className={classNames.join(" ")}>
        <use xlinkHref="/images/icon_text_link_chevron_small.svg#icon" />
      </svg>
    );
  }
  return null;
}

ChevronSmall.defaultProps = {
  linktype: null,
  isActive: false,
  chevronType: null,
};

ChevronSmall.propTypes = {
  linktype: PropTypes.string,
  isActive: PropTypes.bool,
  chevronType: PropTypes.oneOf([
    "chevronRight",
    "chevronRightHollow",
    "chevronExternalLink",
  ]),
};
