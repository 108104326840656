import React from "react";
import PropTypes from "prop-types";
import SbEditable from "storyblok-react";
import Contain from "~components/atoms/contain/contain";
import { BLOK_ARRAY } from "../../../prop_types";
import { footerSectionStyle, footerStyle } from "./footer.module.scss";
import FooterLinksSection from "./footer_components/footer_links_section/footer_links_section";
import FooterLogoRegulatorySection from "./footer_components/footer_logo_regulatory_section/footer_logo_regulatory_section";
import FooterNewsletterSocialSection from "./footer_components/footer_newsletter_social_section/footer_newsletter_social_section";

export default function Footer({
  language,
  newsletterFormHeading,
  newsletterFormId,
  regulatoryDisclaimer,
  sections,
  socialButtons,
  socialButtonsHeading,
  subNavData,
  ...rest
}) {
  return (
    <SbEditable content={rest}>
      <footer className={footerStyle}>
        <Contain>
          <div className={footerSectionStyle}>
            <FooterNewsletterSocialSection
              {...{
                language,
                newsletterFormHeading,
                newsletterFormId,
                socialButtons,
                socialButtonsHeading,
              }}
            />
          </div>

          {/* ————— FOOTER LINK COLUMNS SECTION ———— */}
          <div className={footerSectionStyle}>
            <FooterLinksSection
              {...{
                sections,
                subNavData,
              }}
            />
          </div>

          <FooterLogoRegulatorySection
            regulatoryDisclaimer={regulatoryDisclaimer}
          />
        </Contain>
      </footer>
    </SbEditable>
  );
}

Footer.defaultProps = {
  language: "en-gb",
  newsletterFormHeading: "",
  newsletterFormId: "",
  regulatoryDisclaimer: "",
  sections: [],
  socialButtons: [],
  socialButtonsHeading: "",
  subNavData: [],
};

Footer.propTypes = {
  language: PropTypes.string,
  newsletterFormHeading: PropTypes.string,
  newsletterFormId: PropTypes.string,
  regulatoryDisclaimer: PropTypes.string,
  sections: BLOK_ARRAY,
  socialButtons: BLOK_ARRAY,
  socialButtonsHeading: PropTypes.string,
  subNavData: PropTypes.arrayOf(PropTypes.shape({})),
};
