/* eslint-disable import/prefer-default-export */
import { removeDefaultLanguagePrefix } from "~utils/url_utils";
import { ensureTrailingSlash } from "../../url_utils/ensureTrailingSlash/ensureTrailingSlash";

export function getLinkPropsInternal(link) {
  let internalUrl = link.story?.url || link.cached_url || link.href;

  internalUrl = removeDefaultLanguagePrefix(internalUrl);

  // Ensure the internal link begins with a "/"
  if (!internalUrl.startsWith("/")) {
    internalUrl = `/${internalUrl}`;
  }

  // should include URL search params if it is an anchor link to the same page
  if (typeof window !== "undefined") {
    const { pathname } = window.location;

    if (pathname === internalUrl) {
      const queryString = window.location.search;

      internalUrl = `${internalUrl}${queryString}`;
    }
  }
  let type = "internalLink";

  // Set up anchor
  if (link.anchor) {
    internalUrl = `${internalUrl}#${link.anchor}`;
    type = "anchorLink";
  } else {
    internalUrl = ensureTrailingSlash(internalUrl);
  }

  const linkProps = {
    target: "_self",
    to: internalUrl,
    type,
  };

  if (link?.state) {
    linkProps.state = link.state;
  }

  return linkProps;
}
