import PropTypes from "prop-types";
import React from "react";

import SVGIcon from "~components/utils/svg_icon/svg_icon";

import { trailingIconStyle } from "./button_trailing_icon.module.scss";

export default function ButtonTrailingIcon({ icon, iconSize, isActive }) {
  return (
    <div className={trailingIconStyle}>
      <SVGIcon
        icon={icon}
        width={iconSize}
        height={iconSize}
        isActive={isActive}
      />
    </div>
  );
}

ButtonTrailingIcon.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  isActive: PropTypes.bool,
};

ButtonTrailingIcon.defaultProps = {
  icon: "",
  iconSize: 20,
  isActive: false,
};
