/* eslint-disable max-classes-per-file, no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";

// Custom error class used to filter error types
export class StoryblokError extends Error {}

let iframeReady;

class HandleStoryblokErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return error instanceof StoryblokError ? { error } : {};
  }

  componentDidMount() {
    if (window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__) {
      iframeReady = window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__.iframeReady;
    }
  }

  componentDidCatch(e) {
    if (e instanceof StoryblokError && iframeReady) {
      window.__REACT_ERROR_OVERLAY_GLOBAL_HOOK__.iframeReady = function customIframeReady() {
        iframeReady();
        if (e instanceof StoryblokError) {
          const overlay = document.querySelector("iframe:last-child");
          if (overlay) {
            overlay.parentNode.removeChild(overlay);
          }
        }
      };
    }
  }

  render() {
    if (this.state.error) {
      return <h1>{this.state.error.message}</h1>;
    }

    return this.props.children;
  }
}

HandleStoryblokErrors.propTypes = {
  children: PropTypes.node.isRequired,
};

export function withHandleStoryblokErrors(Component) {
  return (props) => {
    return (
      <HandleStoryblokErrors>
        <Component {...props} />
      </HandleStoryblokErrors>
    );
  };
}
