import { createUrlPathFromArray } from "~utils/create_url_path_from_array/create_url_path_from_array";
import { inEditor } from "~utils/storyblok/storyblok";
import { removeDefaultLanguagePrefix } from "~utils/url_utils/remove_default_language_prefix/remove_default_language_prefix";

export default function getCanonicalSlug({
  origin,
  fullSlug,
  programmaticPagePath,
}) {
  const isInEditor = inEditor();

  if (isInEditor) {
    return "https://truelayer.com/";
  }

  // Some types of template will need to explicitly pass a page slug - e.g. where they
  // are 100% programmatically generated like a list page template
  if (programmaticPagePath) {
    const canonicalSlug = createUrlPathFromArray([
      origin,
      programmaticPagePath,
    ]);
    return removeDefaultLanguagePrefix(canonicalSlug);
  }

  const canonicalSlug = createUrlPathFromArray([origin, fullSlug]);
  return removeDefaultLanguagePrefix(canonicalSlug);
}
