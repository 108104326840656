import React from "react";
import PropTypes from "prop-types";
import ButtonLeadingIcon from "../button_leading_icon/button_leading_icon";
import ButtonTrailingIcon from "../button_trailing_icon/button_trailing_icon";

function ButtonInnerContent({ isActive, leadingIcon, title, trailingIcon }) {
  return (
    <>
      {leadingIcon && (
        <ButtonLeadingIcon
          isActive={isActive}
          icon={leadingIcon}
          iconSize={18}
        />
      )}

      {title}

      {trailingIcon && (
        <ButtonTrailingIcon
          isActive={isActive}
          icon={trailingIcon}
          iconSize={18}
        />
      )}
    </>
  );
}

ButtonInnerContent.propTypes = {
  isActive: PropTypes.bool,
  leadingIcon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  trailingIcon: PropTypes.string,
};
ButtonInnerContent.defaultProps = {
  isActive: false,
  leadingIcon: null,
  trailingIcon: null,
};

export default ButtonInnerContent;
