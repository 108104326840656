// extracted by mini-css-extract-plugin
export var columnGapStyle = "grid_new-module--columnGapStyle---jYD0";
export var grid = "grid_new-module--grid--VQilX";
export var rowGapStyle = "grid_new-module--rowGapStyle--GhVz4";
export var split_L_1 = "grid_new-module--split_L_1--+QQne";
export var split_L_2 = "grid_new-module--split_L_2--P3WmG";
export var split_L_3 = "grid_new-module--split_L_3--xpT4o";
export var split_L_4 = "grid_new-module--split_L_4--F1HD2";
export var split_L_5 = "grid_new-module--split_L_5--5PVWm";
export var split_L_6 = "grid_new-module--split_L_6--sLMSC";
export var split_M_1 = "grid_new-module--split_M_1--ZqBKP";
export var split_M_2 = "grid_new-module--split_M_2--jfgIV";
export var split_M_3 = "grid_new-module--split_M_3--SvlDS";
export var split_M_4 = "grid_new-module--split_M_4--33Cb-";
export var split_M_5 = "grid_new-module--split_M_5--rv86F";
export var split_M_6 = "grid_new-module--split_M_6--IPbY3";
export var split_S_1 = "grid_new-module--split_S_1--UNpLe";
export var split_S_2 = "grid_new-module--split_S_2--+a2SA";
export var split_S_3 = "grid_new-module--split_S_3--vT+Ea";
export var split_S_4 = "grid_new-module--split_S_4--Pds5M";
export var split_S_5 = "grid_new-module--split_S_5--E8Nb+";
export var split_S_6 = "grid_new-module--split_S_6--vnZ3T";
export var split_XL_1 = "grid_new-module--split_XL_1----16A";
export var split_XL_2 = "grid_new-module--split_XL_2--lt7Kv";
export var split_XL_3 = "grid_new-module--split_XL_3--EsjrP";
export var split_XL_4 = "grid_new-module--split_XL_4--tEiy3";
export var split_XL_5 = "grid_new-module--split_XL_5--qiz7g";
export var split_XL_6 = "grid_new-module--split_XL_6--706cz";
export var split_XS_1 = "grid_new-module--split_XS_1--cbGzY";
export var split_XS_2 = "grid_new-module--split_XS_2--8OEsU";
export var split_XS_3 = "grid_new-module--split_XS_3--UPaBw";
export var split_XS_4 = "grid_new-module--split_XS_4--AZXFC";
export var split_XS_5 = "grid_new-module--split_XS_5--it28c";
export var split_XS_6 = "grid_new-module--split_XS_6--FK3uy";
export var split_XXL_1 = "grid_new-module--split_XXL_1--FhZKp";
export var split_XXL_2 = "grid_new-module--split_XXL_2--HnUSl";
export var split_XXL_3 = "grid_new-module--split_XXL_3--mmbiO";
export var split_XXL_4 = "grid_new-module--split_XXL_4--s3YOj";
export var split_XXL_5 = "grid_new-module--split_XXL_5--4gGEp";
export var split_XXL_6 = "grid_new-module--split_XXL_6--PBHud";
export var split_XXS_1 = "grid_new-module--split_XXS_1--PHaDr";
export var split_XXS_2 = "grid_new-module--split_XXS_2--cNPWb";
export var split_XXS_3 = "grid_new-module--split_XXS_3--9A1Ao";
export var split_XXS_4 = "grid_new-module--split_XXS_4--IA0Sy";
export var split_XXS_5 = "grid_new-module--split_XXS_5--A2dj-";
export var split_XXS_6 = "grid_new-module--split_XXS_6--6N2xK";
export var stretch = "grid_new-module--stretch--hOSee";