export default function getNavTransition({
  currentStep,
  prevStep,
  transitionClassnames,
}) {
  if (currentStep === null || prevStep === null) {
    return transitionClassnames.default;
  }
  if (currentStep < prevStep) {
    return transitionClassnames.right;
  }
  if (currentStep > prevStep) {
    return transitionClassnames.left;
  }
  return transitionClassnames.default;
}
