import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { BLOK_ARRAY } from "../../../prop_types";
import {
  containStyle,
  isContentCenteredStyle,
  isTextCenteredStyle,
  sizeExtraSmallStyle,
  sizeSmallStyle,
} from "./contain.module.scss";

export default function Contain({
  children,
  small,
  extraSmall,
  isContentCentered,
  isTextCentered,
}) {
  const containClassNames = classNames(containStyle, {
    [sizeSmallStyle]: small,
    [sizeExtraSmallStyle]: extraSmall,
    [isContentCenteredStyle]: isContentCentered,
    [isTextCenteredStyle]: isTextCentered,
  });
  return <div className={containClassNames}>{children}</div>;
}

Contain.defaultProps = {
  children: null,
  small: false,
  extraSmall: false,
  isContentCentered: false,
  isTextCentered: false,
};

Contain.propTypes = {
  children: PropTypes.oneOfType([BLOK_ARRAY, PropTypes.node]),
  small: PropTypes.bool,
  extraSmall: PropTypes.bool,
  isContentCentered: PropTypes.bool,
  isTextCentered: PropTypes.bool,
};
