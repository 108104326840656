import React from "react";
import PropTypes from "prop-types";

const BalanceTextComponent = ({ children }) => {
  return <div className="balance-text">{children}</div>;
};

export default BalanceTextComponent;

BalanceTextComponent.propTypes = {
  children: PropTypes.node,
};

BalanceTextComponent.defaultProps = {
  children: null,
};
