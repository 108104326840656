import React from "react";
import PropTypes from "prop-types";
import GridNew from "~components/atoms/grid_new/grid_new";
import Heading from "~components/atoms/heading/heading";
import ButtonTertiary from "~components/molecules/button/button_tertiary/button_tertiary";
import { RESPONSIVE_VALUE_NEW } from "../../../../../../../prop_types";
import { sectionWrapperStyle } from "./footer_column_section.module.scss";

const BUTTON_MARGIN = { marginBottom: "xs", marginTop: "xs" };

function FooterColumnSection({ title, items, gridSplit }) {
  return (
    <div className={sectionWrapperStyle}>
      {/* ————— SECTION TITLE ————— */}
      <Heading visualLevel={7} seoLevel={3} isUppercase color="cloudBurst">
        {title}
      </Heading>

      {/* ————— SECTION ITEMS  ————— */}
      <GridNew hasRowGap={false} split={gridSplit}>
        {items?.length > 0 &&
          items.map((item) => {
            if (item?.link && item?.title) {
              return (
                <ButtonTertiary
                  isFontRegular
                  margin={BUTTON_MARGIN}
                  link={item.link}
                  title={item.title}
                  key={item.title}
                />
              );
            }
            return null;
          })}
      </GridNew>
    </div>
  );
}

FooterColumnSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  gridSplit: RESPONSIVE_VALUE_NEW,
};

FooterColumnSection.defaultProps = {
  title: null,
  items: null,
  gridSplit: { XL: "1" },
};

export default FooterColumnSection;
