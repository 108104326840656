import React from "react";
import PropTypes from "prop-types";
import useDebouncedHoverInteraction from "~components/organisms/header/header_components/desktop_nav/hooks/use_debounced_menu_interactions/use_debounced_menu_interactions";
import { BLOK_ARRAY } from "../../../../../prop_types";
import {
  desktopNavStyle,
  listItemStyle,
  navigationMenuStyle,
} from "./desktop_nav.module.scss";
import DesktopNavDropdownController from "./desktop_nav_components/desktop_nav_dropdown_controller/desktop_nav_dropdown_controller";
import NavGroupItem from "./desktop_nav_components/desktop_nav_group_item/nav_group_item";

export default function DesktopNav({
  items,
  isHeaderScrolled,
  isMobileMenuOpen,
}) {
  const {
    currentStep,
    prevStep,
    debouncedSetActiveItem,
    setActiveItem,
    abandonDebouncedSetActiveItem,
  } = useDebouncedHoverInteraction();

  if (isMobileMenuOpen) {
    return null;
  }

  return (
    <nav
      className={desktopNavStyle}
      onMouseLeave={debouncedSetActiveItem}
      onBlur={debouncedSetActiveItem}
      onMouseEnter={abandonDebouncedSetActiveItem}
      onFocus={abandonDebouncedSetActiveItem}
    >
      {/* ——————————————————————————————————————————————————————————————
        //      TOP LEVEL MENU ITEMS                                          
        // —————————————————————————————————————————————————————————————— */}
      <menu className={navigationMenuStyle} title="Navigation">
        {items &&
          items.map((item, index) => {
            return (
              <li
                key={item._uid}
                onMouseLeave={abandonDebouncedSetActiveItem}
                onBlur={abandonDebouncedSetActiveItem}
                title={item.title}
                className={listItemStyle}
              >
                <NavGroupItem
                  {...{
                    ...item,
                    index,
                    isActive: currentStep === index,
                    isHeaderScrolled,
                    debouncedSetActiveItem,
                    setActiveItem,
                  }}
                />
              </li>
            );
          })}
      </menu>

      <DesktopNavDropdownController
        items={items}
        isMobileMenuOpen={isMobileMenuOpen}
        currentStep={currentStep}
        prevStep={prevStep}
      />
    </nav>
  );
}

DesktopNav.defaultProps = {
  items: null,
  isHeaderScrolled: false,
  isMobileMenuOpen: false,
  callbackWhenSubMenuOpen: null,
};

DesktopNav.propTypes = {
  items: BLOK_ARRAY,
  isHeaderScrolled: PropTypes.bool,
  isMobileMenuOpen: PropTypes.bool,
  callbackWhenSubMenuOpen: PropTypes.func,
};
