import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  azureStyle,
  chevronDownTransitionStyle,
  chevronExternalLinkTransitionStyle,
  chevronLeftTransitionStyle,
  chevronRightTransitionStyle,
  cloudBurst10Style,
  cloudBurst30Style,
  cloudBurst50Style,
  cloudBurst80Style,
  cloudBurstStyle,
  electricVioletStyle,
  isActiveStyle,
  lightGray130Style,
  sunStyle,
  whiteStyle,
  wrapperStyle,
} from "./svg_icon.module.scss";

const COLOR_DICT = {
  azure: azureStyle,
  cloudBurst: cloudBurstStyle,
  cloudBurst80: cloudBurst80Style,
  cloudBurst50: cloudBurst50Style,
  cloudBurst30: cloudBurst30Style,
  cloudBurst10: cloudBurst10Style,
  lightGray130: lightGray130Style,
  electricViolet: electricVioletStyle,
  sun: sunStyle,
  white: whiteStyle,
  default: azureStyle,
};

export default function SVGIcon({
  alt,
  color,
  height,
  icon,
  opacity,
  width,
  isActive,
}) {
  const iconClassNames = classNames(wrapperStyle, {
    [COLOR_DICT[color] || COLOR_DICT.default]: color,
    [isActiveStyle]: isActive,
    [chevronDownTransitionStyle]: icon === "icon_chevron_down",
    [chevronExternalLinkTransitionStyle]: icon === "icon_chevron_external_link",
    [chevronLeftTransitionStyle]: icon === "icon_chevron_left",
    [chevronRightTransitionStyle]: icon === "icon_chevron_right",
  });

  const style = {};

  if (width) {
    style.width = `${width}px`;
  }

  if (height) {
    style.height = `${height}px`;
  }

  if (opacity) {
    style.opacity = `${opacity}`;
  }

  return (
    <svg
      data-testid="svg-icon"
      viewBox={`0 0 ${width} ${height}`}
      className={iconClassNames}
      style={style}
      alt={alt}
    >
      <use xlinkHref={`/images/icons/${icon}.svg#icon`} />
    </svg>
  );
}

SVGIcon.defaultProps = {
  alt: "Icon",
  color: null,
  height: 32,
  opacity: 100,
  width: 32,
  isActive: false,
};

SVGIcon.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  icon: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  width: PropTypes.number,
  isActive: PropTypes.bool,
};
