import PropTypes from "prop-types";
import React from "react";

import ChevronSmall from "~components/atoms/chevron_small/chevron_small";

import * as classes from "./mobile_nav_group.module.scss";

function MobileNavGroup({ title, isActive }) {
  const buttonClassNames = [classes.button];

  if (isActive) {
    buttonClassNames.push(classes.isActive);
  }

  return (
    <div className={buttonClassNames.join(" ")} type="button">
      {title || "Group"}
      <ChevronSmall chevronType="chevronRight" isActive={isActive} />
    </div>
  );
}

MobileNavGroup.defaultProps = {
  title: "",
  isActive: false,
};

MobileNavGroup.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
};

export default MobileNavGroup;
