/* eslint-disable import/prefer-default-export, camelcase */
import { inEditor } from "../../storyblok/storyblok";
import { getLinkPropsAsset } from "./getLinkPropsAsset";
import { getLinkPropsEmail } from "./getLinkPropsEmail";
import { getLinkPropsExternal } from "./getLinkPropsExternal";
import { getLinkPropsInternal } from "./getLinkPropsInternal";

const ENV = process.env.GATSBY_ENV || "development";

export const getLinkProps = (link) => {
  if (ENV !== "production" && inEditor()) {
    return {};
  }
  if (!link) {
    return {};
  }
  if (!link.url && !link.story?.url && !link.href) {
    return {};
  }
  if (link.linktype === "story") {
    return getLinkPropsInternal(link);
  }
  if (link.linktype === "url") {
    return getLinkPropsExternal(link);
  }
  if (link.linktype === "asset") {
    return getLinkPropsAsset(link);
  }
  if (link.linktype === "email") {
    return getLinkPropsEmail(link);
  }
  // Use internal link as assumed fallback
  return getLinkPropsInternal(link);
};
